import React from 'react';
import { ConfigProvider } from '@alife/cn-ui';
import CnAddress from '@alife/cn-address/lib/view';
import fp from 'lodash/fp';
import isEmpty from 'lodash/isEmpty';
export const AddressWrapper = (props) => {
    const { value, onChange, fieldValue, setValue, parentIndex, ruleItemListCellIndex, } = props;
    return (React.createElement(ConfigProvider, { prefix: 'cn-next-' },
        React.createElement(CnAddress, { multiple: true, value: value, iso: 'CN', maxLevel: 3, config: {
                apiModel: 'cdn',
                cdnUrl: 'https://division-data.alicdn.com/simple/addr_mtop_20220401.js',
            }, maxTagCount: 1, useDetailValue: true, showFooter: true, showSearch: true, onChange: (data) => {
                const v = isEmpty(data) ? null : data;
                onChange && onChange(v);
                setValue(fp.set([
                    'ruleList',
                    parentIndex,
                    'ruleItemList',
                    ruleItemListCellIndex,
                    'matchValueList',
                ], v, fieldValue));
            } })));
};
