import React from 'react';
import { Input } from '@alife/cn-ui';
function TestTextArea(props) {
    const { value, onChange, tip } = props;
    return (React.createElement("div", null,
        React.createElement(Input.TextArea, { value: value, onChange: onChange }),
        tip));
}
TestTextArea.displayName = 'TestTextArea';
export { TestTextArea };
