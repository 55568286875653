import $i18n from '@alife/panda-i18n';
import React from 'react';

export const getSomeStatusJobDesc = (data) => {
  const result = [];
  const test = [];
  if (Number.isInteger(data.successCount)) {
    test.push(
      $i18n.get(
        {
          id: 'SuccessfulDataSuccessCount',
          dm: '成功{dataSuccessCount}条',
        },
        { dataSuccessCount: data.successCount },
      ),
    );
  }
  if (Number.isInteger(data.errorCount)) {
    test.push(
      $i18n.get(
        {
          id: 'FailedDataErrorCountBar',
          dm: '失败{dataErrorCount}条',
        },
        { dataErrorCount: data.errorCount },
      ),
    );
  }
  result.push(test.join('，'));
  if (data.reason) {
    result.push(data.reason);
  }
  if (result.length) {
    return (
      <>
        <div>{result[0]}</div>
        {result[1] ? <div style={{ marginTop: 2 }}>{result[1]}</div> : null}
      </>
    );
  }
  return null;
};
export const downloadFile = (url) => {
  const elink = document.createElement('a');
  elink.style.display = 'none';
  elink.target = '_blank';
  elink.href = url;
  document.body.appendChild(elink);
  elink.click();
  document.body.removeChild(elink);
};
