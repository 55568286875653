import $i18n from '@alife/panda-i18n';
import React from 'react';
export default {
    Export: $i18n.get({ id: 'Export', dm: '导出' }),
    PleaseRecord: $i18n.get({ id: 'PleaseSelectARecord', dm: '请选择一条记录' }),
    CreateExportTask: $i18n.get({ id: 'CreateExportTask', dm: '创建导出任务' }),
    FillTaskName: $i18n.get({ id: 'ExportFileNaming', dm: '导出文件命名' }),
    plsInput: $i18n.get({ id: 'gxms-pc_Enter.CNTM', dm: '请输入' }),
    Mandatory: $i18n.get({ id: 'Required', dm: '必填项' }),
    Cancel: $i18n.get({ id: 'Cancel', dm: '取消' }),
    Ok: $i18n.get({ id: 'Determine', dm: '确定' }),
    ExportError: $i18n.get({ id: 'ExportFailed', dm: '导出失败' }),
    ExportSuccessful: $i18n.get({ id: 'ExportSuccessful', dm: '导出成功' }),
    ClickProgress: $i18n.get({ id: 'ClickToViewProgress', dm: '点击查看进度' }),
    SystemError: $i18n.get({ id: '31255242834317312.CNTM', dm: '系统异常' }),
    NetworkError: $i18n.get({ id: 'NetworkAnomaly', dm: '网络异常' }),
    Exporting: $i18n.get({ id: 'Exporting', dm: '导出中...' }),
    taskCreatedAndJump: (n) => {
        return (React.createElement(React.Fragment, null,
            $i18n.get({
                id: 'TheTaskWasCreatedSuccessfullyClick_344111788',
                dm: '任务创建成功，点击到',
            }),
            n,
            $i18n.get({ id: 'View', dm: '查看' })));
    },
    taskCenter: $i18n.get({ id: 'MissionCenter', dm: '任务中心' }),
    exportSelected: $i18n.get({ id: 'ExportSelected', dm: '导出已选' }),
    selectedAll: $i18n.get({ id: 'ExportAll', dm: '导出全部' }),
    exportRecords: $i18n.get({ id: 'ExportRecord', dm: '导出记录' }),
    successText: $i18n.get({ id: 'ExportSuccessful', dm: '导出成功' }),
    failedText: $i18n.get({ id: 'ExportFailed', dm: '导出失败' }),
    download: $i18n.get({ id: 'Download', dm: '下载' }),
    retry: $i18n.get({ id: 'Retry', dm: '重试' }),
};
