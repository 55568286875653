import $i18n from '@alife/panda-i18n';
import './style/index.scss';
import React, { useCallback, useEffect, useState, useRef, useMemo, } from 'react';
import { CnDialog, CnTable, CnMessage } from '@alife/cn-ui';
import Code from './components/code';
import ImagePreview from './components/image-preview';
import request from './request';
import { getHostName } from './utils';
// !!! 注意 ViewProps 会被 ../index 使用
export var SceneEnum;
(function (SceneEnum) {
    SceneEnum["RECEIVING"] = "RECEIVING";
    SceneEnum["ABNORMAL_REGISTRATION"] = "ABNORMAL_REGISTRATION";
    SceneEnum["HANDOVER_OPERATION"] = "HANDOVER_OPERATION";
    SceneEnum["REFUND_DIFF_RESOURCE"] = "REFUND_DIFF_RESOURCE";
    SceneEnum["PACKAGE_HANDLE"] = "PACKAGE_HANDLE";
    SceneEnum["REFUND"] = "REFUND";
    SceneEnum["PUT_AWAY"] = "PUT_AWAY";
    SceneEnum["PICK"] = "PICK";
    SceneEnum["HU_PACKING"] = "HU_PACKING";
    SceneEnum["PICK_BILL_PACKING"] = "PICK_BILL_PACKING";
    SceneEnum["HU_INSPECTION"] = "HU_INSPECTION";
    SceneEnum["BOM_INSPECTION"] = "BOM_INSPECTION";
    SceneEnum["EXCESS_STOCK_REGISTER"] = "EXCESS_STOCK_REGISTER";
    SceneEnum["ANDON_STOCK_REGISTER"] = "ANDON_STOCK_REGISTER";
    SceneEnum["EXCESS_REVERT_DIVIDE"] = "EXCESS_REVERT_DIVIDE";
    SceneEnum["EXCESS_REVERT"] = "EXCESS_REVERT";
})(SceneEnum || (SceneEnum = {}));
let isMutiSelectOpen = false;
function View({ onOk, onCancel, dataSource = [], onChange = () => { }, }) {
    const [selectedRowKey, setSelectedRowKey] = useState(null);
    const selectedRowKeyRef = useRef(null);
    const dataSourceRef = useRef(null);
    const columns = useMemo(() => [
        {
            title: $i18n.get({ id: 'CommodityCode', dm: '商品编码' }),
            dataIndex: 'itemCode',
            align: 'right',
            // width: 200,
            cell: (barcode) => {
                return React.createElement(Code, { value: barcode, hightlightNum: 4 });
            },
        },
        {
            title: $i18n.get({ id: 'NameOfCommodity', dm: '商品名称' }),
            dataIndex: 'itemName',
            // width: 200,
            cell: (name) => {
                return name;
            },
        },
        {
            title: $i18n.get({ id: 'ProductionDate', dm: '生产日期' }),
            dataIndex: 'productionDate',
        },
        {
            title: $i18n.get({ id: 'CommodityPicture', dm: '商品图片' }),
            // width: 120,
            dataIndex: 'imageUrl',
            cell: (url) => {
                if (!url) {
                    return null;
                }
                return React.createElement(ImagePreview, { image: url });
            },
        },
    ], []);
    const handleOk = useCallback(() => {
        onOk && onOk(null);
    }, [onOk]);
    // 初始化选中逻辑
    useEffect(() => {
        dataSourceRef.current = dataSource;
        setSelectedRowKey(dataSource?.[0]?.itemId);
    }, [dataSource]);
    // 监听 selectedRowKey
    useEffect(() => {
        selectedRowKeyRef.current = selectedRowKey;
        const selected = dataSourceRef?.current?.find((item) => item.itemId === selectedRowKey);
        onChange(selected || null);
    }, [selectedRowKey, onChange]);
    // 绑定键盘事件
    useEffect(() => {
        const callBack = function (e) {
            const { key = '' } = e || {};
            const currentNum = dataSourceRef?.current?.findIndex((item) => item.itemId === selectedRowKeyRef.current) || 0;
            if (key === 'ArrowUp') {
                const targetIndex = parseInt(`${Math.max(currentNum - 1, 0)}`, 10);
                setSelectedRowKey(dataSourceRef?.current?.[targetIndex]?.itemId || null);
                setTimeout(() => {
                    document.querySelector('.next-table-row.selected')?.scrollIntoView();
                });
            }
            else if (key === 'ArrowDown') {
                const targetIndex = parseInt(`${Math.min(currentNum + 1, (dataSourceRef?.current?.length || 0) - 1)}`, 10);
                setSelectedRowKey(dataSourceRef?.current?.[targetIndex]?.itemId || null);
                setTimeout(() => {
                    document.querySelector('.next-table-row.selected')?.scrollIntoView();
                });
            }
            if (key === 'Enter') {
                return handleOk();
            }
            if (key === 'Escape') {
                onCancel && onCancel();
            }
        };
        document.addEventListener('keyup', callBack);
        return () => {
            document.removeEventListener('keyup', callBack);
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(CnTable
        // fixedHeader
        // maxBodyHeight={300}
        , { 
            // fixedHeader
            // maxBodyHeight={300}
            size: 'small', columns: columns, dataSource: dataSource, primaryKey: 'itemId', rowSelection: {
                type: 'single',
                selectedRowKey: [selectedRowKey],
                onChange: (keys, records) => {
                    setSelectedRowKey(keys);
                    onChange(records);
                },
            } })));
}
export function showMultiItemsDialog(props) {
    const { title = $i18n.get({
        id: 'PleaseSelectSpecificProducts',
        dm: '请选择具体商品',
    }), onOk, onCancel, paramsList, scene, ...rest } = props;
    const selectedRowDataRef = {
        current: null,
    };
    if (isMutiSelectOpen === true) {
        return;
    }
    isMutiSelectOpen = true;
    const handleOk = () => {
        isMutiSelectOpen = false;
        onOk && onOk(selectedRowDataRef.current);
    };
    const handleCancel = () => {
        isMutiSelectOpen = false;
        onCancel && onCancel();
    };
    const showDialog = (dataSource) => {
        const dialog = CnDialog.show({
            title,
            className: 'multi-item-dialog',
            style: { width: 900 },
            onOk: () => {
                dialog?.hide?.();
                handleOk();
            },
            onCancel: () => {
                dialog?.hide?.();
                handleCancel();
            },
            onClose: () => {
                dialog?.hide?.();
                handleCancel();
            },
            content: (React.createElement(View, { ...rest, dataSource: dataSource, onOk: () => {
                    dialog?.hide?.();
                    handleOk();
                }, onCancel: () => {
                    dialog?.hide?.();
                    handleCancel();
                }, onChange: (v) => {
                    selectedRowDataRef.current = v;
                } })),
        });
    };
    const getFooterDataSource = async () => {
        const hostName = getHostName();
        let csrfId = null;
        try {
            // csrf 经常挂，但是实际上请求不依赖csrf的值
            const res = await request({
                url: `${hostName}/api/csrf`,
                method: 'get',
                withCredentials: true,
            });
            csrfId = res?.data?.token;
        }
        catch (e) {
            console.log(e);
        }
        try {
            request({
                url: `${hostName}/api/wmsBase/queryItemInfo`,
                method: 'post',
                data: {
                    csrfId,
                    _csrf: csrfId,
                    data: {
                        scene,
                        itemIdOwnerIdBatchIdTupleList: paramsList,
                    },
                },
                withCredentials: true,
            }).then((res) => {
                const { itemInfoVOList = [] } = (res?.data || {});
                const ds = itemInfoVOList?.map((item, index) => {
                    if (index === 0) {
                        return {
                            ...item,
                            selected: true,
                        };
                    }
                    return item;
                }) || [];
                showDialog(ds);
            }, (error) => {
                console.log(error);
            });
        }
        catch (e) {
            CnMessage.error($i18n.get({
                id: 'ErrorInObtainingProductInformationList_2087455607',
                dm: '获取商品信息列表错误',
            }));
        }
    };
    getFooterDataSource();
}
export default View;
