import $i18n from '@alife/panda-i18n';
import React from 'react';
import PropTypes from 'prop-types';
import { CnMessage, CnButton } from '@alife/cn-ui';

export default function FormDownload(props) {
  const {
    children,
    action,
    enctype,
    method,
    target,
    params,
    _state = {},
  } = props;
  const { csrf = {} } = _state;
  const { token } = csrf;
  const onClick = () => {
    if (!token) {
      CnMessage.show({
        type: 'error',
        content: $i18n.get({
          id: 'InterfaceVerificationFailed',
          dm: '接口校验失败',
        }),
      });
      return;
    }
    const form = document.createElement('form');
    form.style = 'display:none;';
    form.action = action;
    form.enctype = enctype;
    form.method = method;
    form.target = target;
    document.body.appendChild(form);
    params.csrfId = token;
    Object.keys(params).forEach((key) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = params[key];
      form.appendChild(input);
    });
    form.submit();
    form.remove();
  };
  return (
    <CnButton
      className='form-download-btn'
      data-hottag-name='cn-sc-wms-biz-download-form'
      onClick={onClick}
    >
      {children}
    </CnButton>
  );
}

export { FormDownload };

FormDownload.displayName = 'FormDownload';

FormDownload.propTypes = {
  /** 内置state */
  _state: PropTypes.object,
  /** 按钮名称 */
  children: PropTypes.string,
  /** 下载地址 */
  action: PropTypes.string,
  /** 请求方式 */
  method: PropTypes.string,
  /** 编码方式 */
  enctype: PropTypes.string,
  /** 打开方式 */
  target: PropTypes.string,
  /** 入参 */
  params: PropTypes.object,
};

FormDownload.defaultProps = {
  _state: {},
  children: $i18n.get({ id: 'FormDownload', dm: '表单下载' }),
  action: '',
  enctype: 'multipart/form-data',
  method: 'POST',
  target: '',
  params: {},
};
