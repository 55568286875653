import $i18n from '@alife/panda-i18n';
import React, { useMemo, useState } from 'react';
import { CnButton } from '@alife/cn-ui';
import { componentName } from './const';
import Step2 from './components/step2';
import Step1 from './components/step1';
import './index.scss';
/**
 * 定制业务组件，无复用能力
 * 供 移位管理 使用
 */
const BizMoveLocation = (prop) => {
    const { mode, record = {} } = prop || {};
    const { id: propId } = record;
    const [visible, setVisible] = useState(false);
    const [newId, setNewId] = useState(null);
    const id = useMemo(() => {
        return newId || propId;
    }, [newId, propId]);
    const isAddMode = useMemo(() => {
        return mode === 'new';
    }, [mode]);
    const btnProps = useMemo(() => {
        if (!isAddMode) {
            return {
                component: 'a',
                text: true,
                type: 'primary',
                style: { height: 'auto' }
            };
        }
        return {};
    }, [isAddMode]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CnButton, { onClick: () => setVisible(true), ...btnProps }, isAddMode
            ? $i18n.get({ id: 'New', dm: '新增' })
            : $i18n.get({ id: 'Revised', dm: '修改' })),
        isAddMode && !newId && visible && (React.createElement(Step1, { setNewId: setNewId, isAddMode: isAddMode, visible: visible, setVisible: setVisible })),
        ((isAddMode && newId) || !isAddMode) && visible && (React.createElement(Step2, { setNewId: setNewId, id: id, isAddMode: isAddMode, visible: visible, setVisible: setVisible }))));
};
BizMoveLocation.displayName = componentName;
export { BizMoveLocation };
