import $i18n from '@alife/panda-i18n';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Button } from '@alife/cn-ui';
import cnRequest from 'src/api';

export default function DownloadBtnPkmt(props) {
  const { _bindFilterData = {} } = props;
  const onClick = async () => {
    const res = await cnRequest({
      url: '/api/pkmt/inventory/export',
      method: 'POST',
      data: {
        ..._bindFilterData,
      },
      responseType: 'blob',
    });
    const url = URL.createObjectURL(res);
    const elink = document.createElement('a');
    elink.style.display = 'none';
    elink.target = '_blank';
    elink.href = url;
    document.body.appendChild(elink);
    elink.click();
    document.body.removeChild(elink);
  };
  return (
    <div className={styles.downloadBtnPkmt}>
      <Button data-hottag-name='cn-sc-wms-biz-pkmt-download' onClick={onClick}>
        {$i18n.get({ id: 'ExportInventory', dm: '导出库存' })}
      </Button>
    </div>
  );
}

export { DownloadBtnPkmt };

DownloadBtnPkmt.displayName = 'DownloadBtnPkmt';

DownloadBtnPkmt.propTypes = {
  _bindFilterData: PropTypes.object,
};

DownloadBtnPkmt.defaultProps = {
  children: null,
  _bindFilterData: {},
};
