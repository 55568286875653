import $i18n from '@alife/panda-i18n';
import React, { useCallback, useEffect, useState } from 'react';
import { CnMessage, CnButton, Form, CnAsyncSelect, 
// Table,
CnInput, Grid, CnIcon, CnNumberPicker, CnTable, } from '@alife/cn-ui';
import { componentName } from './const';
import cnRequest from 'src/api';
import * as _ from 'lodash';
import { BatchInput } from '../batch-input';
import './index.scss';
const FormItem = Form.Item;
const { Row, Col } = Grid;
/**
 * 定制业务组件，无复用能力
 * 供 b2b换货 使用
 */
const B2bSwapDialog = ({ _context = {}, _dialogRef = {}, }) => {
    const openDialogMode = _context?.state?.valueOf?.CnDialog5?.openDialogMode;
    let _orderCodeList = '';
    let _batchQualityStatusChangesEnums = [];
    if (openDialogMode === 'new') {
        const searchParams = new URLSearchParams(window.location.search);
        _orderCodeList = searchParams.get('orderCodeList');
        _batchQualityStatusChangesEnums = (searchParams.get('batchQualityStatusChangesEnums') || '').split(',');
    }
    const [reasons, setReasons] = useState([]);
    const [orderCodeList, setOrderCodeList] = useState(_orderCodeList || '');
    const [batchQualityStatusChangesEnums, setBatchQualityStatusChangesEnums] = useState(_batchQualityStatusChangesEnums || []);
    const [list, setList] = useState([]);
    const [fixHeaders, setFixHeaders] = useState([]);
    const [loading, setLoading] = useState(false);
    const renderValue = useCallback((value, index, records) => {
        if (records.isSplit) {
            return '-';
        }
        return _.toString(value);
    }, []);
    const renderOperate = useCallback((value, index, record) => {
        if (record.isSplit) {
            return (React.createElement(CnButton, { onClick: () => {
                    let _list = list.filter((_item, i) => i !== index);
                    _list = _list.map((d, _index) => ({
                        ...d,
                        serialNumber: _index + 1,
                    }));
                    const sum = sumSwapQtyByDataIndexFront(record.dataIndexFront, _list);
                    const _index = _list.findIndex((d) => d.dataIndexFront === record.dataIndexFront && !d.isSplit);
                    const last = _list[_index].planQty - sum - _list[_index].diffQty;
                    _list[_index].allocatedQty = sum;
                    _list[_index].waitAllocateQty = last;
                    setList(_list);
                } },
                React.createElement(CnIcon, { type: 'minus' })));
        }
        return [
            React.createElement(CnButton, { key: `${index}add`, onClick: () => {
                    let tmpList = [...list];
                    tmpList.splice(index + 1, 0, {
                        ...record,
                        isSplit: true,
                        pickCabinetCode: '',
                        toItemBatchId: '',
                        // toItemBatchCode: '',
                        swapQty: '',
                        error: null,
                    });
                    tmpList = tmpList.map((d, _index) => ({
                        ...d,
                        serialNumber: _index + 1,
                    }));
                    setList(tmpList);
                }, style: { marginRight: 8 } },
                React.createElement(CnIcon, { type: 'add' })),
            !record.isSplit && !list[index + 1]?.isSplit && list?.length > 1 && (React.createElement(CnButton, { key: `${index}minus`, onClick: () => {
                    let _list = list.filter((_item, i) => i !== index);
                    _list = _list.map((d, _index) => ({
                        ...d,
                        serialNumber: _index + 1,
                    }));
                    // const sum = sumSwapQtyByDataIndexFront(
                    //   record.dataIndexFront,
                    //   _list,
                    // );
                    // const _index = _list.findIndex(
                    //   (d) => d.dataIndexFront === record.dataIndexFront && !d.isSplit,
                    // );
                    // const last =
                    //   _list[_index]?.planQty - sum - _list[_index]?.diffQty;
                    // _list[_index].allocatedQty = sum;
                    // _list[_index].waitAllocateQty = last;
                    setList(_list);
                } },
                React.createElement(CnIcon, { type: 'minus' }))),
        ];
    }, [list]);
    const sumSwapQtyByDataIndexFront = (dataIndexFront, arr) => arr.reduce((sum, { dataIndexFront: index, swapQty }) => index === dataIndexFront ? _.toNumber(sum) + _.toNumber(swapQty) : sum, 0);
    const onBlur = useCallback((index, value, type, record, title) => {
        if (type === 'swapQty' || type === 'diffQty') {
            if (value < 0 || !value || !_.isInteger(_.toNumber(value))) {
                value = 0;
            }
        }
        const _list = list.map((item, idx) => {
            if (idx !== index) {
                return item;
            }
            if (type === 'swapQty' || type === 'diffQty') {
                value = _.toNumber(value);
            }
            return { ...item, [type]: value };
        });
        if (type === 'swapQty' || type === 'diffQty') {
            let sum = sumSwapQtyByDataIndexFront(record.dataIndexFront, _list);
            const _index = _list.findIndex((d) => d.dataIndexFront === record.dataIndexFront && !d.isSplit);
            let last = _list[_index]?.planQty - sum - _list[_index]?.diffQty;
            if (last < 0) {
                _list[index][type] = 0;
                sum = sumSwapQtyByDataIndexFront(record.dataIndexFront, _list);
                last = _list[_index]?.planQty - sum - _list[_index]?.diffQty;
                _list[_index].allocatedQty = sum;
                _list[_index].waitAllocateQty = last;
                setList(_list);
                return CnMessage.show({
                    type: 'error',
                    duration: 8000,
                    content: $i18n.get({
                        id: 'CurrentLineRecordSerialNumberfieldTitleVarianceQty_82406103',
                        dm: '当前行{recordSerialNumber}-字段{title}：【差异数量】+【已分配数量】不能大于【待分配数量】(页面提示)',
                    }, { recordSerialNumber: record.serialNumber, title }),
                });
            }
            _list[_index].allocatedQty = sum;
            _list[_index].waitAllocateQty = last;
        }
        if (type === 'toCabinetCode') {
            _list.forEach((d) => {
                if (d.dataIndexFront === record.dataIndexFront) {
                    d[type] = value;
                }
            });
        }
        setList(_list);
    }, [list, setList]);
    const onChangeData = useCallback((index, value, type, record, title) => {
        if (type === 'swapQty' || type === 'diffQty') {
            if (value < 0 || !value || !_.isInteger(_.toNumber(value))) {
                value = 0;
            }
        }
        const _list = list.map((item, idx) => {
            if (idx !== index) {
                return item;
            }
            if (type === 'swapQty' || type === 'diffQty') {
                value = _.toNumber(value);
            }
            return { ...item, [type]: value };
        });
        if (type === 'swapQty' || type === 'diffQty') {
            const sum = sumSwapQtyByDataIndexFront(record.dataIndexFront, _list);
            const _index = _list.findIndex((d) => d.dataIndexFront === record.dataIndexFront && !d.isSplit);
            const last = _list[_index]?.planQty - sum - _list[_index]?.diffQty;
            // _list[_index].allocatedQty = sum;
            // _list[_index].waitAllocateQty = last;
            if (last < 0) {
                return CnMessage.error($i18n.get({
                    id: 'CurrentLineRecordSerialNumberfieldTitleVarianceQuantity_853246394',
                    dm: '当前行{recordSerialNumber}-字段{title}：差异数量+已分配数量不能大于待分配数量(页面提示)',
                }, { recordSerialNumber: record.serialNumber, title }));
            }
        }
        if (type === 'toCabinetCode') {
            _list.forEach((d) => {
                if (d.dataIndexFront === record.dataIndexFront) {
                    d[type] = value;
                }
            });
        }
        setList(_list);
    }, [list, setList]);
    const headers = [
        {
            title: $i18n.get({ id: 'SerialNumber', dm: '序号' }),
            dataIndex: 'serialNumber',
            width: 80,
        },
        ...fixHeaders?.map((item) => {
            let { width } = item;
            const { dataIndex, title } = item;
            if (width === undefined) {
                width = 90;
            }
            return {
                title,
                dataIndex,
                cell: renderValue,
                width,
            };
        }),
        {
            title: $i18n.get({ id: 'AllocatedQuantity', dm: '已分配数量' }),
            dataIndex: 'allocatedQty',
            width: 150,
            cell: (value, index, record) => {
                if (record.isSplit) {
                    return null;
                }
                return React.createElement("span", null, value);
            },
        },
        {
            title: $i18n.get({ id: 'UnallocatedQuantity', dm: '未分配数量' }),
            dataIndex: 'waitAllocateQty',
            width: 150,
            cell: (value, index, record) => {
                if (record.isSplit) {
                    return null;
                }
                return React.createElement("span", null, value);
            },
        },
        {
            title: $i18n.get({ id: '31255238589681664.CNTM', dm: '差异数量' }),
            dataIndex: 'diffQty',
            width: 150,
            cell: (value, index, record) => {
                if (record.isSplit) {
                    return null;
                }
                return (React.createElement(CnNumberPicker, { size: 'small', value: value, onChange: (val) => onChangeData(index, val, 'diffQty', record, $i18n.get({ id: '31255238589681664.CNTM', dm: '差异数量' })), onBlur: (e) => onBlur(index, e?.target?.value, 'diffQty', record, $i18n.get({ id: '31255238589681664.CNTM', dm: '差异数量' })), style: { width: '100px' }, precision: 0 }));
            },
        },
        {
            title: $i18n.get({ id: 'ReplacementShelf', dm: '换货上架库位' }),
            dataIndex: 'toCabinetCode',
            width: 150,
            cell: (value, index, record) => {
                if (record.isSplit) {
                    return null;
                }
                return (React.createElement(CnInput, { size: 'small', value: value, onChange: (val) => onChangeData(index, val, 'toCabinetCode', record, ''), style: { width: '100px' }, width: '100' }));
            },
        },
        {
            title: $i18n.get({ id: 'ReallocateLocation', dm: '重新分配库位' }),
            dataIndex: 'pickCabinetCode',
            width: 150,
            cell: (value, index, record) => {
                return (React.createElement(CnInput, { size: 'small', value: value, onChange: (val) => onChangeData(index, val, 'pickCabinetCode', record, ''), style: { width: '100px' }, width: '100' }));
            },
        },
        {
            title: $i18n.get({ id: 'ReassignBatchId', dm: '重新分配批次Id' }),
            dataIndex: 'toItemBatchId',
            width: 150,
            cell: (value, index, record) => {
                return (React.createElement(CnNumberPicker, { size: 'small', value: value, onChange: (val) => onChangeData(index, val, 'toItemBatchId', record, ''), style: { width: '100px' }, precision: 0 }));
            },
        },
        // {
        //   title: '重新分配批次号',
        //   dataIndex: 'toItemBatchCode',
        //   width: 150,
        //   cell: (value: string, index: number, record: any) => {
        //     return (
        //       <CnInput
        //         size='small'
        //         value={value}
        //         onChange={(val) =>
        //           onChangeData(index, val, 'toItemBatchCode', record, '')
        //         }
        //         style={{ width: '100px' }}
        //       />
        //     );
        //   },
        // },
        {
            title: $i18n.get({ id: 'AllocatedQuantity_FPSL', dm: '分配数量' }),
            dataIndex: 'swapQty',
            width: 150,
            cell: (value, index, record) => {
                return (React.createElement(CnNumberPicker, { size: 'small', value: value, onChange: (val) => onChangeData(index, val, 'swapQty', record, $i18n.get({ id: 'AllocatedQuantity_FPSL', dm: '分配数量' })), onBlur: (e) => onBlur(index, e?.target?.value, 'swapQty', record, $i18n.get({ id: 'AllocatedQuantity_FPSL', dm: '分配数量' })), style: { width: '100px' }, precision: 0 }));
            },
        },
        {
            title: $i18n.get({ id: 'Split', dm: '拆分' }),
            cell: renderOperate,
            width: 125,
            lock: 'right',
        },
    ];
    const getReasons = useCallback(() => {
        cnRequest({
            url: '/api/swap/goods/getBatchQualityStatusChangesEnums',
            method: 'get',
        }).then((response) => {
            setReasons(response?.data || []);
        });
    }, []);
    const getList = useCallback(() => {
        setLoading(true);
        cnRequest({
            url: '/api/swap/goods/querySwapGoods',
            method: 'post',
            data: {
                orderCodeList: orderCodeList?.split(','),
                batchQualityStatusChangesCondition: batchQualityStatusChangesEnums,
            },
        })
            .then((response) => {
            const data = response?.data;
            const _list = (data?.tableData || []).map((d, index) => ({
                pickCabinetCode: '',
                toItemBatchId: '',
                // toItemBatchCode: '',
                swapQty: '',
                allocatedQty: 0,
                diffQty: 0,
                waitAllocateQty: d.planQty,
                ...d,
                dataIndexFront: index,
                serialNumber: openDialogMode === 'new' || openDialogMode === 'edit'
                    ? index + 1
                    : d.serialNumber,
            }));
            setFixHeaders(data?.tableColumns || []);
            setList(_list);
            setLoading(false);
        })
            .catch(() => {
            setLoading(false);
        });
    }, [
        setFixHeaders,
        setList,
        orderCodeList,
        batchQualityStatusChangesEnums,
        setLoading,
    ]);
    const buildParams = useCallback(() => {
        const errorList = [];
        list.forEach((item) => {
            Object.entries(item).forEach(([key, value]) => {
                if (value === '' && key !== 'diffQty' && item.isSplit) {
                    const title = (headers || []).find((d) => d.dataIndex === key)?.title;
                    errorList.push({ item, emptyFields: title });
                }
            });
        });
        if (errorList && errorList.length > 0) {
            const rowNum = errorList[0]?.item?.serialNumber;
            const field = errorList[0]?.emptyFields;
            CnMessage.error($i18n.get({
                id: 'CurrentLineRowNumFieldfieldcanno_568082310',
                dm: '当前行{rowNum}: 字段-{field}-不能为空(页面提示)',
            }, { rowNum, field }));
            return false;
        }
        const __list = list.map(({ diffQty, ...last }) => ({
            ...last,
            diffQty: last.isSplit ? '' : diffQty,
        }));
        const filterEmpty = (obj) => Object.entries(obj)
            .filter(([, val]) => val !== '')
            .reduce((result, [key, val]) => ({ ...result, [key]: val }), {});
        const _list = __list.map(filterEmpty);
        const swapGoodsDetailsRequestList = _list.map((d) => ({
            ...d,
            extendProperties: {
                ...d,
            },
        }));
        const data = {
            batchQualityStatusChangesEnums,
            swapGoodsDetailsRequestList,
        };
        return data;
    }, [list]);
    const onConfirm = useCallback(() => {
        const data = buildParams();
        if (!data)
            return;
        cnRequest({
            method: 'POST',
            url: '/api/swap/goods/confirm',
            data,
        }).then(() => {
            _dialogRef?.close?.();
            CnMessage.success($i18n.get({
                id: 'ReplacementConfirmationDocumentCreatedSuccessfully',
                dm: '换货确认单创建成功',
            }));
        });
    }, [buildParams]);
    const handleOk = useCallback(() => {
        onConfirm();
    }, [list, onConfirm]);
    useEffect(() => {
        getReasons();
        if (openDialogMode === 'new') {
            getList();
        }
    }, []);
    return (React.createElement("div", { className: 'b2b-confirm' },
        React.createElement("div", null, $i18n.get({ id: 'DocumentStatusInOperation', dm: '单据状态：作业中' })),
        React.createElement(Form, null,
            React.createElement(Row, { gutter: '4' },
                React.createElement(Col, { span: '8' },
                    React.createElement(FormItem, { required: true, label: $i18n.get({ id: 'LBXOddNumber', dm: 'LBX单号' }), requiredMessage: $i18n.get({
                            id: 'LBXDocumentNumberIsARequired_272650846',
                            dm: 'LBX单号是必填字段',
                        }) },
                        React.createElement(BatchInput, { size: 'small', value: orderCodeList, name: 'orderCodeList', maxCount: 100, onChange: (value) => {
                                setOrderCodeList(value);
                            } }))),
                React.createElement(Col, { span: '8' },
                    React.createElement(FormItem, { label: $i18n.get({ id: 'LotStatusChange', dm: '批号状态变更' }) },
                        React.createElement(CnAsyncSelect, { size: 'small', style: { width: '100%' }, value: batchQualityStatusChangesEnums, name: 'batchQualityStatusChangesEnums', maxTagCount: 2, mode: 'multiple', dataSource: reasons, onChange: (value) => {
                                setBatchQualityStatusChangesEnums(value);
                            } }))),
                React.createElement(Col, { className: 'b2b-search-col', span: '8' },
                    React.createElement(Form.Submit, { validate: true, onClick: (values, errors) => {
                            if (errors) {
                                return;
                            }
                            getList();
                        }, style: { marginBottom: 12, height: 32 }, type: 'primary' }, $i18n.get({ id: 'Search', dm: '搜索' }))))),
        React.createElement(CnTable, { loading: loading, columns: headers, dataSource: list }),
        React.createElement("div", { className: 'b2b-confirm-footer cn-dialog-alc-footer' },
            React.createElement(CnButton, { type: 'secondary', onClick: () => {
                    _dialogRef?.close?.();
                }, style: { marginRight: 8 } }, $i18n.get({ id: 'Cancel', dm: '取消' })),
            React.createElement(CnButton, { type: 'primary', onClick: () => handleOk() }, $i18n.get({ id: 'Determine', dm: '确定' })))));
};
B2bSwapDialog.displayName = componentName;
export { B2bSwapDialog };
