import $i18n from '@alife/panda-i18n';
import React from 'react';
// import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Button, formilyReact, CnMessage } from '@alife/cn-ui';
import cnRequest from 'src/api';
import unionBy from 'lodash/unionBy';
export default function CncEditableTableFormBtn() {
  const form = formilyReact.useForm();
  const { values } = formilyReact.useForm();
  const onClick = () => {
    const barcode = values?.barcode;
    const ownerId = values?.ownerId;
    const detailList = values?.detailList;
    cnRequest({
      url: '/api/pkmt/inventory/item',
      method: 'POST',
      data: {
        barcode,
        attribute: 3,
        ownerId,
        paging: {
          current: 1,
          pageSize: 100,
        },
      },
    }).then(
      (data) => {
        if (data) {
          const { tableData = [] } = data.data;
          const [resItem = {}] = tableData;
          cnRequest({
            url: '/api/pkmt/inventory/info',
            method: 'POST',
            data: {
              ownerId: resItem.partnerId,
              itemId: resItem.itemId,
            },
          }).then((res) => {
            if (res) {
              const tableItem = { ...resItem, ...res.data };
              const newDetailList = unionBy([tableItem], detailList, 'itemId');
              form.setFieldState('detailList', (state) => {
                state.value = [...newDetailList];
              });
            }
          });
        }
      },
      (error) => {
        const { message, code } = error;

        if (message || code) {
          CnMessage.error(message || code);
        }
      },
    );
  };
  return (
    <div className={styles.cncEditableTableFormBtn}>
      <Button onClick={onClick}>{$i18n.get({ id: 'New', dm: '新增' })}</Button>
    </div>
  );
}

export { CncEditableTableFormBtn };

CncEditableTableFormBtn.displayName = 'CncEditableTableFormBtn';

// CncEditableTableFormBtn.propTypes = {
//   children: PropTypes.node,
// };

CncEditableTableFormBtn.defaultProps = {
  children: null,
};
