import './grid-container.scss';

import React, { useState } from 'react';

export default function GridContainer({ children, width = 155.5 }) {
  const [columns, setColumns] = useState(1);

  return (
    <ul
      ref={(r) => {
        const rect = r?.getBoundingClientRect?.();

        if (rect?.width) {
          setColumns(Math.floor(rect?.width / width));
        }
      }}
      className={'grid-ontainer'}
      style={{
        '--col-count': columns,
      }}
    >
      {children}
    </ul>
  );
}
