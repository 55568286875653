import React, { useCallback, useEffect } from 'react';
import { CnInput, CnAsyncSelect, formilyReact } from '@alife/cn-ui';
import PropTypes from 'prop-types';
import cnRequest from 'src/api';

const { useField } = formilyReact;

let dataSource = [];
let LoadingRequest;

export default function DynamicInputOrSelect(props) {
  const { dependent, flagString } = props;
  const dependentCode = dependent.param;
  const field = useField?.();
  const path = field.path.segments;
  const parentValue = field.query('..').value();
  const flag = parentValue[path[1]][dependentCode] != flagString;
  const { value } = field;

  useEffect(() => {
    if (!LoadingRequest && !props?.dataSource?.length) {
      LoadingRequest = cnRequest({
        url: '/api/packaging/management/allDefaultBox',
        method: 'GET',
        data: {},
      }).then((res) => {
        dataSource = res.data;
      });
    }
  }, []);

  const changeValue = useCallback(
    (v) => {
      field.form.setFieldState(path[0], (state) => {
        const tempData = [...parentValue];
        if (v) {
          tempData[path[1]][path[2]] = Array.isArray(v) ? v : v.split(',');
        } else {
          tempData[path[1]][path[2]] = null;
        }
        state.value = tempData;
      });
    },
    [field.form, parentValue, path],
  );
  return (
    <>
      {flag ? (
        <CnInput
          {...props}
          value={Array.isArray(value) ? value.join(',') : value}
          onChange={(v) => changeValue(v)}
        />
      ) : (
        <CnAsyncSelect
          {...props}
          dataSource={props?.dataSource?.length ? props.dataSource : dataSource}
          value={
            props?.mode === 'single' && Array.isArray(value)
              ? value.join('')
              : value
          }
          onChange={(v) => changeValue(v)}
        />
      )}
    </>
  );
}

DynamicInputOrSelect.displayName = 'DynamicInputOrSelect';

DynamicInputOrSelect.propTypes = {
  dependent: PropTypes.object || undefined,
  flagString: PropTypes.string,
  dataSource: PropTypes.array || undefined,
  mode: PropTypes.string,
};

DynamicInputOrSelect.defaultProps = {
  dependent: {},
  flagString: '',
  dataSource: [],
  mode: '',
};

export { DynamicInputOrSelect };
