import React from 'react';
// import PropTypes, { string } from 'prop-types';
import './index.scss';
import {
  CnInputGroup,
  CnAsyncSelect,
  CnIcon,
  CnImageViewer,
} from '@alife/cn-ui';

export default function FormItemWithIconClick(props) {
  const {
    value,
    onChange,
    // type = 'select',
    imageURL = '//img.alicdn.com/tfs/TB1vV3jlTtYBeNjy1XdXXXXyVXa-1377-466.png',
  } = props;

  const dataSource =
    props?._getContext()?.state?.CnFormDialog5?.roadwayOptions?.map((road) => {
      return {
        label: road.code,
        value: road.id,
      };
    }) || [];

  return (
    <div className={'form-item-with-icon-click'}>
      <CnInputGroup className={'input-group'}>
        <CnAsyncSelect
          value={value}
          onChange={onChange}
          dataSource={dataSource}
          className={'select'}
        />
        <CnIcon
          type={'icon-info-r'}
          onClick={() => {
            CnImageViewer.open({
              src: imageURL,
            });
          }}
          className={'icon'}
        />
      </CnInputGroup>
    </div>
  );
}

export { FormItemWithIconClick };

FormItemWithIconClick.displayName = 'FormItemWithIconClick';

FormItemWithIconClick.propTypes = {
  // children: PropTypes.node,
  // type: string,
};

FormItemWithIconClick.defaultProps = {
  // children: null,
  value: '',
  onChange: () => {},
  // type: 'select',
};
