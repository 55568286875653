import $i18n from '@alife/panda-i18n';
import React, { useCallback } from 'react';
import { CnMessage, CnTable } from '@alife/cn-ui';
import cnRequest from 'src/api';
const dynamicTablePrimaryId = 'id';
export default function DynamicTable({ loadDetail, id, setData, data, dataRef, dynamicSelected, setDynamicSelected, handleQuery, dynamicTableLoading, }) {
    const checkValidate = useCallback((selected) => {
        let pass = true;
        selected?.forEach((row) => {
            const { id: rowId } = row;
            const sourceItem = data.dynamicTable?.items?.find((item) => item.id === rowId);
            if (sourceItem?.warehouseAreaTaskStyle === 5) {
                if (!sourceItem?.availableInvQuantity) {
                    CnMessage.error($i18n.get({
                        id: 'TheNumberOfAvailableShiftsIs_1060194509',
                        dm: '可用移位数量为空！',
                    }));
                    pass = false;
                    return;
                }
                return;
            }
            else if (typeof sourceItem?.planQuantity !== 'object' ||
                (!sourceItem?.planQuantity.box && !sourceItem?.planQuantity.piece)) {
                CnMessage.error($i18n.get({
                    id: 'PleaseFillInThePlannedShift_962747783',
                    dm: '请填写计划移位数量！',
                }));
                pass = false;
                return;
            }
            const { box, piece } = sourceItem?.planQuantity;
            if (box * sourceItem?.boxRegulation + (piece || 0) >
                sourceItem?.availableInvQuantity) {
                CnMessage.error($i18n.get({
                    id: 'PlannedShiftQuantityExceedsAvailableInventory_1207928535',
                    dm: '计划移位数量超出可用库存数',
                }));
                pass = false;
            }
        });
        return pass;
    }, [data.dynamicTable?.items]);
    const handleMove = useCallback((records) => {
        let validPass = true;
        const details = records?.map((record) => {
            const { id: rowId } = record;
            const sourceItem = data.dynamicTable?.items?.find((item) => item.id === rowId);
            const { inventoryStatus, warehouseAreaId, cabinetId, warehouseAreaTaskStyle, boxRegulation, availableInvQuantity, planQuantity = {
                box: 0,
                piece: 0,
            }, } = sourceItem || {};
            validPass = checkValidate(records);
            if (!validPass) {
                return;
            }
            return {
                ...sourceItem,
                qualityStatus: inventoryStatus,
                fromWarehouseAreaId: warehouseAreaId,
                fromCabinetId: cabinetId,
                planQuantity: warehouseAreaTaskStyle === 5
                    ? availableInvQuantity
                    : (planQuantity?.box || 0) * (boxRegulation || 0) +
                        (planQuantity?.piece || 0),
            };
        });
        if (!validPass) {
            return;
        }
        cnRequest({
            url: '/api/move/detail/add',
            method: 'POST',
            data: {
                details,
                orderId: `${id}`,
            },
        }).then((res) => {
            const { success, error } = res;
            if (success) {
                CnMessage.success($i18n.get({ id: 'AddedSuccessfully', dm: '添加成功' }));
                setData({
                    ...(dataRef?.current || {}),
                    dynamicTable: {
                        ...(dataRef?.current?.dynamicTable || {}),
                        items: dataRef?.current?.dynamicTable?.items?.map((item) => {
                            return {
                                ...item,
                                planQuantity: {
                                    box: '',
                                    piece: '',
                                },
                            };
                        }),
                    },
                });
                setDynamicSelected([]);
                loadDetail({
                    currentPage: 1,
                });
            }
            else {
                CnMessage.error(error?.message ||
                    error?.code ||
                    $i18n.get({ id: 'UnknownException', dm: '未知异常' }));
            }
        });
    }, [
        checkValidate,
        id,
        loadDetail,
        dataRef,
        setData,
        setDynamicSelected,
        data.dynamicTable?.items,
    ]);
    return (React.createElement(CnTable, { size: 'small', primaryKey: dynamicTablePrimaryId, columns: (data.dynamicTable?.headers || []), dataSource: data.dynamicTable?.items || [], showSelect: true, rowSelection: {
            useDetailValue: true,
            selectedRowKeys: dynamicSelected?.map((item) => item[dynamicTablePrimaryId]),
        }, onSelectChange: (records) => {
            setDynamicSelected(records);
        }, paging: true, pageProps: {
            current: data.dynamicTable?.paging?.currentPage || 1,
            pageSize: data.dynamicTable?.paging?.pageSize || 10,
            pageSizeList: [10, 20, 50],
            total: data.dynamicTable?.paging?.totalItems || 0,
        }, onPageChange: ({ currentPage, pageSize = 10 }) => {
            const tmpData = {
                ...dataRef.current,
                dynamicTable: {
                    ...(dataRef.current?.dynamicTable || {}),
                    paging: {
                        ...(dataRef.current?.dynamicTable?.paging || {}),
                        currentPage,
                        pageSize,
                    },
                },
            };
            setData(tmpData);
            dataRef.current = tmpData;
            handleQuery(false);
        }, onSort: (code, order) => {
            // 实际发现排序后端接口有问题;
            // resetPage设置为false，不需要重置页数
            handleQuery(false, code, order);
            console.log('排序字段', code, order);
            return true;
        }, loading: dynamicTableLoading, operateColumn: {
            buttons: [
                {
                    children: $i18n.get({ id: 'Add', dm: '添加' }),
                    onClick(_event, record) {
                        handleMove([record]);
                    },
                },
            ],
        }, toolbar: {
            batchArea: [
                {
                    children: $i18n.get({ id: 'BatchIncrease', dm: '批量增加' }),
                    type: 'primary',
                    isDisabled: (selectKeys) => !selectKeys?.length,
                    onClick() {
                        handleMove(dynamicSelected);
                    },
                },
            ],
        } }));
}
