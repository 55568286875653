import $i18n from '@alife/panda-i18n';
import React, { useCallback } from 'react';
import classnames from 'classnames';
import { CnTooltip, CnTag, CnIcon, CnButton } from '@alife/cn-ui';
import './index.scss';
const editPageMockData = {
    summary: [
        {
            label: $i18n.get({
                id: 'SummaryInformationComponentEditPageExample_1610268962',
                dm: '汇总信息组件编辑页示例',
            }),
            value: '99999',
            valueType: 'number',
        },
        {
            label: $i18n.get({ id: 'DocumentAddress', dm: '文档地址' }),
            value: 'https://alidocs.dingtalk.com/i/nodes/a9E05BDRVQRkezKGC1945qLyJ63zgkYA',
            valueFontSize: 12,
        },
    ],
    summaryTip: $i18n.get({
        id: 'CustomDataIndicatorsUpToTwoRows',
        dm: '汇总数据展示，最多显示两行',
    }),
};
const isEditPage = location.pathname === '/lowcode/edit';
const TableTopSummary = (props) => {
    const isL2Env = !!props._getCurrentTableInfo;
    // L2中的数据存放位置
    const tableExtra = props._getCurrentTableInfo?.()?.__tableExtra__ || {};
    // console.log('props.summary:', props._getCurrentTableInfo?.())
    const { summary, summaryTip = editPageMockData.summaryTip } = (isEditPage ? editPageMockData : tableExtra) || {};
    const renderTag = useCallback(({ itemName, num, tips, ...other }) => {
        let icon;
        if (tips) {
            icon = (React.createElement(CnTooltip, { trigger: React.createElement(CnIcon, { type: 'info-r', size: 'small', style: { marginLeft: 4 } }) }, tips));
        }
        // const presetColors = ["blue", "green", "orange", "red", "turquoise", "yellow"];
        return (React.createElement(CnTag, { onClick: () => { }, status: 'info', ...other },
            `${itemName}: ${num}`,
            icon));
    }, []);
    /*
      (
        <div className={classnames('table-top-summary', { l2: isL2Env })}>
          {summary.map(
            (item: {
              label: string;
              value: string;
              unit: string;
              valueColor: string;
              valueFontSize: number;
              valueType: string;
            }) => {
              const {
                label = '',
                valueFontSize = 16,
                valueColor = '#397ae8',
                value,
                valueType,
                unit,
              } = item;
    
              const formattedValue =
                valueType === 'number' ? parseFloat(value).toLocaleString() : value;
    
              return (
                <div className='table-top-summary-item'>
                  <span className='table-top-summary-item-label'>
                    {label || ''}
                  </span>
                  <span
                    className='table-top-summary-item-value'
                    style={{
                      fontSize: valueFontSize,
                      color: valueColor,
                    }}
                  >
                    {typeof value === 'undefined' ? '' : formattedValue}
                  </span>
                  <span className='table-top-summary-item-unit'>{unit || ''}</span>
                </div>
              );
            },
          )}
        </div>
      );
    */
    const isShowSumDom = summary?.length > 0;
    if (!isShowSumDom) {
        return null;
    }
    return (React.createElement("div", { className: classnames('table-top-summary', { l2: isL2Env }) },
        React.createElement("div", { className: 'panel-inline-title' },
            React.createElement(CnTooltip, { trigger: React.createElement(CnButton, { type: 'primary', component: 'a', text: true, className: 'panel-custom-habbit-config-btn', onClick: () => { } },
                    $i18n.get({ id: 'SumDataIndicators', dm: '汇总数据' }),
                    React.createElement(CnIcon, { type: 'info-r', size: 'small' }),
                    ":"), align: 't' }, summaryTip)),
        React.createElement("div", { className: 'panel-inline-tags' }, summary?.map((item, index) => {
            const { label = '', valueColor, value, valueType, unit } = item;
            let formattedValue = valueType === 'number' ? parseFloat(value).toLocaleString() : value;
            formattedValue = unit ? `${formattedValue}${unit}` : formattedValue;
            return renderTag({
                itemName: label,
                key: index,
                num: formattedValue,
                tips: item.tip || null,
                ...item,
                color: item.color || valueColor,
            });
        }))));
};
TableTopSummary.displayName = 'TableTopSummary';
export { TableTopSummary };
