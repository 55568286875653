import enUS from './en-US.json';
import jaJP from './ja-JP.json';
import koKR from './ko-KR.json';
import ruRU from './ru-RU.json';
import zhCN from './zh-CN.json';
export default {
    'en-US': enUS,
    'ja-JP': jaJP,
    'ko-KR': koKR,
    'ru-RU': ruRU,
    'zh-CN': zhCN,
};
