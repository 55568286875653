import $i18n from '@alife/panda-i18n';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { CnTable, CnMessage } from '@alife/cn-ui';
// import { withI18n } from '@alife/panda-i18n';
// import locale from 'src/locale';
import cnRequest from 'src/api';
import DetailList from 'src/common/detail-list';
import TitleLine from 'src/common/title-line';
import { componentName } from './const';
import './index.scss';
function SorterRulePreview(props) {
    const { className = '', _context = {} } = props;
    const { state = {} } = _context;
    const { CnDialog3 = {} } = state;
    const { id } = CnDialog3;
    const [value, setValue] = useState({});
    const getChuteInfo = useCallback((detailId) => {
        cnRequest({
            url: '/api/chute_rule/chute_info',
            method: 'GET',
            params: {
                id: detailId,
            },
        }).then((res) => {
            const data = res?.data || {};
            if (data) {
                setValue(data);
            }
        }, (error) => {
            const { message, code } = error;
            if (message || code) {
                CnMessage.error(message || code);
            }
        });
    }, []);
    useEffect(() => {
        getChuteInfo(id);
    }, [id, getChuteInfo]);
    const detailListData = useMemo(() => {
        const { name = '', autoOutboundDesc = '', updateName = '', equipment = { name: '' }, schema = { name: '' }, gmtModifiedDesc = '', gmtCreateDesc = '', } = value;
        const { name: equipmentName } = equipment;
        const { name: schemaName } = schema;
        const tmpData = [
            {
                label: $i18n.get({ id: 'GridName', dm: '格口名称' }),
                value: name,
            },
            {
                label: $i18n.get({ id: 'WhetherToExitTheWarehouse', dm: '是否出库' }),
                value: autoOutboundDesc,
            },
            {
                label: $i18n.get({ id: 'Updaters', dm: '更新人' }),
                value: updateName,
            },
            {
                label: $i18n.get({ id: 'SortingScheme', dm: '分拣方案' }),
                value: schemaName,
            },
            {
                label: '',
                value: '',
            },
            {
                label: $i18n.get({ id: '31255238652596224.CNTM', dm: '更新时间' }),
                value: gmtModifiedDesc,
            },
            {
                label: $i18n.get({ id: 'EquipmentName', dm: '设备名称' }),
                value: equipmentName,
            },
            {
                label: '',
                value: '',
            },
            {
                label: $i18n.get({ id: '31255238560321536.CNTM', dm: '创建时间' }),
                value: gmtCreateDesc,
            },
        ];
        return tmpData;
    }, [value]);
    return (React.createElement("div", { "x-class": [className, 'sorter-rule-detail-wrapper'] },
        React.createElement(TitleLine, { title: $i18n.get({ id: 'BasicInformation', dm: '基础信息' }) }),
        React.createElement(DetailList, { data: detailListData }),
        value?.ruleList?.map((rule) => {
            const { ruleItemList, priorityDesc } = rule;
            return (React.createElement(React.Fragment, null,
                React.createElement(TitleLine, { title: priorityDesc }),
                React.createElement(CnTable, { columns: [
                        {
                            name: $i18n.get({ id: 'MatchField', dm: '匹配字段' }),
                            key: 'matchFieldDesc',
                        },
                        {
                            name: $i18n.get({ id: 'MatchingMode', dm: '匹配方式' }),
                            key: 'matchTypeDesc',
                        },
                        {
                            name: $i18n.get({ id: 'MatchingValue', dm: '匹配值' }),
                            key: 'matchValueDesc',
                            render: (v, record) => {
                                const { matchField, matchValueList, matchValueDescList = [], } = record;
                                if (matchField === 'provinceId' ||
                                    matchField === 'cityId' ||
                                    matchField === 'area' ||
                                    matchField === 'carriageCp' ||
                                    Array.isArray(matchValueList)) {
                                    return matchValueList
                                        ?.map((item, index) => {
                                        const desc = matchValueDescList?.[index]
                                            ? `(${matchValueDescList?.[index]})`
                                            : '';
                                        return `${item}${desc}`;
                                    })
                                        .join('、');
                                }
                                return v;
                            },
                        },
                    ], dataSource: ruleItemList })));
        })));
}
// 此行导致联调预览页面组件不展示
// const SorterRule = withI18n(View, { locale });
SorterRulePreview.displayName = componentName;
export { SorterRulePreview };
