import $i18n from '@alife/panda-i18n';
import React, { useCallback, useEffect, useState, useRef, useMemo, } from 'react';
import debounce from 'lodash/debounce';
import { CnAsyncSelect } from '@alife/cn-ui';
import { getOwners, getItemCenterHost } from './utils';
// 提取默认值，不然会造成死循环，handleSearch依赖变动，导致useEffect一直执行
const defaultParams = { lastActiveOptions: [] };
function OwnerSelect(props) {
    const { params = defaultParams, mode = 'single', value, enableAllUser, onChange, } = props;
    const [dataSource, setDataSource] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [lastFilterText, setLastFilterText] = useState('');
    const [load, setLoad] = useState({ state: 'loading' });
    const valueRef = useRef();
    const setFilterTextDebounced = useCallback(debounce((v = '') => {
        setFilterText(v);
    }, 500), []);
    const handleSearch = useCallback(debounce((v) => {
        if (v) {
            setLastFilterText(v);
        }
        const tmpKeyWord = typeof v === 'string' ? v : '';
        const tmpCustomParams = Object.keys(params)
            .reduce((result, key) => {
            if (key === 'lastActiveOptions' && valueRef.current) {
                if (Array.isArray(value)) {
                    result.push(`${key}=${JSON.stringify(valueRef.current)}`);
                }
                else {
                    result.push(`${key}=${JSON.stringify([valueRef.current])}`);
                }
            }
            else {
                result.push(`${key}=${params[key]}`);
            }
            return result;
        }, [])
            .join('&');
        const customParams = tmpCustomParams ? `&${tmpCustomParams}` : '';
        const hostName = getItemCenterHost(window.location);
        setLoad({
            state: 'loading',
        });
        const source = getOwners(hostName, tmpKeyWord, customParams)
            .then((res) => {
            const { data } = res;
            const { result = [], lastActiveOptions = [] } = data || {};
            if (enableAllUser) {
                setDataSource([
                    { label: '全部货主', value: -1 },
                    ...result,
                    ...lastActiveOptions,
                ]);
            }
            else {
                setDataSource([...result, ...lastActiveOptions]);
            }
            setLoad({});
        })
            .catch(() => {
            setLoad({});
        });
        return source;
    }, 800), [params, value, enableAllUser]);
    const middleOnChange = useCallback((v, x, y) => {
        if (!onChange) {
            return;
        }
        if (mode === 'single') {
            onChange(v, x, y);
        }
        else if (v?.includes(-1)) {
            onChange([-1], x, y);
        }
        else {
            onChange(v, x, y);
        }
    }, [onChange, mode]);
    const formatedDataSource = useMemo(() => {
        if (mode === 'single') {
            return dataSource;
        }
        if (value?.includes(-1)) {
            return dataSource?.map((item) => {
                return item.value === -1 ? item : { ...item, disabled: true };
            });
        }
        return dataSource;
    }, [dataSource, mode, value]);
    useEffect(() => {
        valueRef.current = value;
    }, [value]);
    useEffect(() => {
        if (lastFilterText !== filterText) {
            handleSearch(filterText);
        }
    }, [filterText, lastFilterText, handleSearch, value]);
    // 初始化执行一次
    useEffect(() => {
        handleSearch();
    }, [value]);
    return (React.createElement(CnAsyncSelect, { placeholder: $i18n.get({ id: 'PleaseSelectTheOwner', dm: '请选择货主' }), ...props, onChange: middleOnChange, mode: mode, showSearch: true, hasClear: true, filterLocal: false, autoWidth: false, onSearch: (v) => {
            setLoad({
                state: 'loading',
            });
            setFilterTextDebounced(v);
        }, dataSource: formatedDataSource, ...load }));
}
OwnerSelect.displayName = 'OwnerSelect';
export { OwnerSelect };
