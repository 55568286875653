import $i18n from '@alife/panda-i18n';
import React, { useCallback, useState } from 'react';
import { CnDialog, CnTable, CnMessage } from '@alife/cn-ui';
import cnRequest from 'src/api';
const detailTablePrimaryId = 'id';
export default function DetailTable({ id, data, loadDetail, detailTableLoading, }) {
    const [detailSelected, setDetailSelected] = useState([]);
    const handleDelete = useCallback((ids) => {
        cnRequest({
            url: '/api/move/detail/delete',
            method: 'POST',
            data: { mvOrderId: id, mvOrderDetailIds: ids },
        }).then((res) => {
            if (res?.success) {
                CnMessage.success($i18n.get({ id: 'DeleteSuccessful', dm: '删除成功' }));
                loadDetail({
                    currentPage: 1,
                });
                setDetailSelected([]);
            }
            else {
                CnMessage.error($i18n.get({ id: 'DeleteFailed', dm: '删除失败' }));
            }
        });
    }, [id, loadDetail]);
    return (React.createElement(CnTable, { primaryKey: detailTablePrimaryId, columns: data.detailTable.headers || [], dataSource: data.detailTable.items || [], showSelect: true, rowSelection: {
            useDetailValue: true,
            selectedRowKeys: detailSelected?.map((item) => item[detailTablePrimaryId]),
        }, onSelectChange: (records) => {
            setDetailSelected(records);
        }, paging: true, pageProps: {
            current: data.detailTable?.paging?.currentPage || 1,
            pageSize: data.detailTable?.paging?.pageSize || 10,
            pageSizeList: [10, 20, 50],
            total: data.detailTable?.paging?.totalItems || 0,
        }, onPageChange: ({ currentPage, pageSize = 10 }) => {
            loadDetail({
                currentPage,
                pageSize,
            });
            setDetailSelected([]);
        }, loading: detailTableLoading, operateColumn: {
            buttons: [
                {
                    children: $i18n.get({ id: 'Delete', dm: '刪除' }),
                    onClick(_event, record) {
                        CnDialog.confirm({
                            title: $i18n.get({ id: 'AreYouSureToDelete', dm: '確定刪除?' }),
                            onOk: () => {
                                handleDelete([record[detailTablePrimaryId]]);
                            },
                        });
                    },
                },
            ],
        }, toolbar: {
            batchArea: [
                {
                    children: $i18n.get({ id: 'BatchDelete', dm: '批量刪除' }),
                    type: 'primary',
                    isDisabled: (selectKeys) => !selectKeys?.length,
                    onClick() {
                        CnDialog.confirm({
                            title: $i18n.get({ id: 'AreYouSureToDelete', dm: '確定刪除?' }),
                            onOk: () => {
                                handleDelete(detailSelected?.map((item) => item[detailTablePrimaryId]));
                            },
                        });
                    },
                },
            ],
            toolArea: [
                {
                    children: $i18n.get({ id: '31255239587926016.CNTM', dm: '刷新' }),
                    type: 'primary',
                    onClick() {
                        loadDetail();
                        setDetailSelected([]);
                    },
                },
            ],
        } }));
}
