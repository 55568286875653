/* eslint-disable react/prop-types */
import React, { useState, memo } from 'react';
import { Slider } from '@alife/cn-ui';
import '../../index.scss';

function CustomSlider(props) {
  const {
    imagesList = [],
    setActiveImg = () => {},
    setCurImgViewer = () => {},
    setShowViewer = () => {},
  } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Slider
      activeIndex={activeIndex}
      onChange={(index) => {
        const activeImgTemp = imagesList?.[index] || {};
        setActiveImg(activeImgTemp);
        setActiveIndex(index);
      }}
      style={{
        width: 800,
        height: 430,
        margin: '0 auto',
      }}
      infinite
    >
      {(imagesList || []).map((item, index) => {
        const { url, text } = item;
        return (
          <div key={`${url}_${index}`} className='slider_img_wrapper'>
            <img
              draggable={false}
              src={url}
              alt={text}
              onClick={() => {
                setCurImgViewer([
                  {
                    ...item,
                    src: url,
                  },
                ]);
                setShowViewer(true);
              }}
            />
          </div>
        );
      })}
    </Slider>
  );
}

export default memo(CustomSlider);
