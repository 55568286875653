import $i18n from '@alife/panda-i18n';
import React from 'react';
import styles from './index.module.scss';
import { Button, CnMessage } from '@alife/cn-ui';
import cnRequest from 'src/api';
const qs = require('qs');
export default function DownloadProgress(props) {
    const { _bindFilterData = {}, action, title, method = 'POST', isUrl = false, isMenuItem = false, } = props;
    const onClick = async () => {
        if (!isUrl) {
            try {
                const res = await cnRequest({
                    url: action,
                    method,
                    data: {
                        ..._bindFilterData,
                    },
                    responseType: 'blob',
                });
                const url = URL.createObjectURL(res);
                const elink = document.createElement('a');
                elink.style.display = 'none';
                elink.target = '_self';
                elink.href = url;
                document.body.appendChild(elink);
                elink.click();
                document.body.removeChild(elink);
            }
            catch (error) {
                if (error.type === 'application/json') {
                    return CnMessage.error($i18n.get({ id: 'ExportFailed', dm: '导出失败' }));
                }
                if (error instanceof Blob) {
                    const url = URL.createObjectURL(error);
                    const elink = document.createElement('a');
                    elink.style.display = 'none';
                    elink.target = '_self';
                    elink.href = url;
                    document.body.appendChild(elink);
                    elink.click();
                    document.body.removeChild(elink);
                }
            }
        }
        else {
            window.open(`${action}?${qs.stringify(_bindFilterData)}`);
        }
    };
    return (React.createElement("div", { className: styles.downloadProgress },
        React.createElement(Button, { onClick: onClick, ...(isMenuItem
                ? {
                    component: 'a',
                    text: true,
                }
                : {}) }, title || $i18n.get({ id: 'Export', dm: '导出' }))));
}
export { DownloadProgress };
DownloadProgress.displayName = 'DownloadProgress';
