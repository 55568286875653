import $i18n from '@alife/panda-i18n';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

export default function TableCellAttachments(props) {
  const { value = [] } = props;
  if (!Array.isArray(value) || value.length === 0) {
    return '- -';
  }
  return (
    <div className={styles.tableCellAttachments}>
      {value.map((item = {}, idx = 0) => {
        const tmpIndex = idx + 1;
        const name = $i18n.get(
          {
            id: 'AttachmentTmpIndex',
            dm: '附件{tmpIndex}',
          },
          { tmpIndex },
        );
        return (
          <a
            href={item.downloadURL}
            target='_blank'
            rel='noopener noreferrer'
            key={idx}
            title={name}
          >
            {name}
          </a>
        );
      })}
    </div>
  );
}

export { TableCellAttachments };

TableCellAttachments.displayName = 'TableCellAttachments';

TableCellAttachments.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      downloadURL: PropTypes.string.isRequired,
    }),
  ),
};

TableCellAttachments.defaultProps = {
  value: [],
};
