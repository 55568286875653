import $i18n from '@alife/panda-i18n';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CnButton, CnDialog, Form, Field, NumberPicker, CnMessage, } from '@alife/cn-ui';
import { componentName } from './const';
import cnRequest from 'src/api';
import CnCommonPrint from '@alife/cn-sc-print-base/es/base';
import './index.scss';
/**
 * 定制业务组件
 */
const BizPrintBarcode = (props) => {
    const field = Field.useField();
    const { url = '/api/work/order/entrance', record, children, labelText, progressParams, progressData, isPrintBarcode = true, _getContext, isButton = false, } = props;
    const { state } = _getContext?.() || {};
    const { id, planNum } = record || {};
    const [visible, setVisible] = useState(false);
    const printIns = useMemo(() => {
        return new CnCommonPrint({
            isNeedWetPrint: true,
            isNeedCloudPrint: false,
            isNeedPddPrint: true,
            isNeedJdPrint: true,
            isNeedBytePrint: true,
            isNeedKuaiShouPrint: true,
            isNeedVipPrint: true, // 是否启用唯品会打印
        });
    }, []);
    const handleOk = useCallback(() => {
        const doRequest = (values) => {
            let params = {
                ...values,
            };
            if (id) {
                params.workOrderId = id;
            }
            if (progressParams) {
                params = progressParams(params, state, record);
            }
            cnRequest({
                url: typeof url === 'string' ? url : url(state, record),
                method: 'post',
                data: params,
            }).then((response) => {
                const { standardData, documents = [], ...extraData } = progressData
                    ? progressData(response?.data, state, record) || {}
                    : response?.data;
                const docs = [];
                // 两个分支走向 standardData 控制接口直接返回多个打印任务，documents 控制接口返回单个打印任务但是根据nums进行重复nums次打印
                if (standardData) {
                    standardData.forEach((item) => {
                        if (!item) {
                            return;
                        }
                        const { documents: _documents, ...rest } = item;
                        const packagePrintData = {
                            printConfig: {
                                doPrintAlarm: false,
                                ...rest,
                            },
                            data: _documents,
                        };
                        printIns?.printData?.(packagePrintData);
                    });
                    if (standardData.length >= 1) {
                        CnMessage.success($i18n.get({ id: 'InitiatingPrintTask', dm: '打印任务发起中...' }));
                        setVisible(false);
                    }
                    else {
                        CnMessage.error($i18n.get({ id: 'NoDataAvailableToPrint', dm: '无可打印数据' }));
                    }
                    return;
                }
                if (documents) {
                    documents.forEach((doc) => {
                        const { configName = '', contents = [] } = doc;
                        contents.forEach(({ data = {}, templateCode = '', templateURL = '' }) => docs.push({
                            printConfig: {
                                configName,
                                printType: 'cloud',
                                templateCode,
                                templateUrl: templateURL,
                                extraData,
                            },
                            data: Array(values.printCount).fill(data),
                        }));
                    });
                }
                if (docs.length >= 1) {
                    CnMessage.success($i18n.get({ id: 'InitiatingPrintTask', dm: '打印任务发起中...' }));
                    setVisible(false);
                    printIns.doPrint(docs);
                }
                else {
                    CnMessage.error($i18n.get({ id: 'NoDataAvailableToPrint', dm: '无可打印数据' }));
                }
            });
        };
        if (isPrintBarcode) {
            field.validate((errors, values) => {
                if (errors) {
                    return;
                }
                doRequest(values);
            });
        }
        else {
            doRequest({});
        }
    }, [id, printIns, field, url, state]);
    useEffect(() => {
        if (visible) {
            field.setValues({
                printCount: planNum || 1,
            });
        }
    }, [visible, planNum, field]);
    const title = useMemo(() => {
        return (children ||
            (isPrintBarcode
                ? $i18n.get({ id: 'PrintBarcode', dm: '打印条码' })
                : $i18n.get({ id: '31255238681956352.CNTM', dm: '打印' })));
    }, [children, isPrintBarcode]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CnButton, { onClick: () => (isPrintBarcode ? setVisible(true) : handleOk()), ...(isButton
                ? { type: 'normal' }
                : {
                    type: 'primary',
                    component: 'a',
                    text: true,
                    style: { display: 'inline' },
                }) }, title),
        React.createElement(CnDialog, { className: 'change-dialog', title: title, visible: isPrintBarcode && visible, onOk: handleOk, onCancel: () => setVisible(false), onClose: () => setVisible(false), size: 'medium' },
            React.createElement(Form, { field: field, className: 'form-wrapper' },
                React.createElement(Form.Item, { label: labelText || $i18n.get({ id: 'BarcodeQuantity', dm: '条码数量' }), required: true, requiredMessage: labelText
                        ? `${labelText}不能为空`
                        : $i18n.get({
                            id: 'EnterTheNumberOfBarcodes',
                            dm: '请输入条码数量',
                        }) },
                    React.createElement(NumberPicker, { name: 'printCount', style: { width: '100%' } }))))));
};
BizPrintBarcode.displayName = componentName;
export { BizPrintBarcode };
