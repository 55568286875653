import React, { useEffect, useState } from 'react';
import { componentName } from './const';
import WmsPrint from './wms-print';
import CnCommonPrint from '@alife/cn-sc-print-base/es/base';
import './index.scss';
export function usePrintInstance(props = { isMixPrint: false }) {
    const { isMixPrint } = props;
    const [printInstance, setPrintInstance] = useState(null);
    useEffect(() => {
        if (isMixPrint) {
            setPrintInstance(new CnCommonPrint({
                isNeedWetPrint: true,
                isNeedCloudPrint: true,
                isNeedPddPrint: true,
                isNeedJdPrint: true,
                isNeedBytePrint: true,
                isNeedKuaiShouPrint: true,
                isNeedVipPrint: true, // 是否启用唯品会打印
            }));
        }
        setPrintInstance(new CnCommonPrint());
    }, []);
    return printInstance;
}
function View(props) {
    const { jsonProps } = props;
    const targetProps = jsonProps
        ? {
            ...props,
            ...jsonProps,
        }
        : props;
    return React.createElement(WmsPrint, { ...targetProps });
}
View.displayName = componentName;
View.usePrintInstance = usePrintInstance;
View.CnCommonPrint = CnCommonPrint;
export default View;
