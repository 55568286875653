import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

export default function TableCellColorfulText(props) {
  const { value = {}, statusSetting = [] } = props;
  const statusMap = useMemo(() => {
    const map = {
      success: '#46bc15',
      warning: '#fccc12',
      error: '#ff3000',
      normal: '#1f2633',
    };
    statusSetting.forEach((item = {}) => {
      map[item.state] = item.color;
    });
    return map;
  }, [statusSetting]);
  if (!value && typeof value !== 'number') {
    return null;
  }
  const color = statusMap[value.state] || statusMap.normal;
  if (typeof value === 'string') {
    return <span>{value}</span>;
  }
  return <span style={{ color }}>{value.value}</span>;
}

export { TableCellColorfulText };

TableCellColorfulText.displayName = 'TableCellColorfulText';

TableCellColorfulText.propTypes = {
  statusSetting: PropTypes.arrayOf(
    PropTypes.shape({
      state: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
  value: PropTypes.shape({
    state: PropTypes.string,
    value: PropTypes.string,
  }),
};

TableCellColorfulText.defaultProps = {
  statusSetting: [],
  value: {},
};
