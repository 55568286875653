import 'src/i18n';
import BatchInput from './batch-input';
import Tip from './tip';
import CncEditableTableFormBtn from './editable-table-form-btn';
import FormDownload from './form-download';
import DownloadBtnPkmt from './download-btn-pkmt';
import DownloadProgress from './download-progress';
import Export from './export';
import Print from './print';
import B2bProgress from './biz-b2b-progress';
import b2bAddBox from './biz-b2b-add-box';
import b2bGenerateBox from './biz-b2b-generate-box';
import b2bConfirm from './biz-b2b-confirm';
import B2bSwapDialog from './biz-b2b-swap-dialog';
import ExportNew from './export-new';
import BizMoveLocatipon from './biz-move-location';
import FormItemWithIconClick from './form-item-with-icon-click';
import MultiItems from './multi-items';
import OwnerSelect from './owner-select';
import SorterRule from './sorter-rule';
import SorterRulePreview from './sorter-rule-preview';
import TableCellColorfulText from './table-cell-colorful-text';
import TableCellImage from './table-cell-image';
import TableCellJsx from './table-cell-jsx';
import FormJsx from './form-jsx';
import FormRelativeTime from './form-relative-time';
import BizDialogParkConfig from './biz-dialog-park-config';
import Textarea from './textarea';
import UploadBtnPkmt from './upload-btn-pkmt';
import UploadProgress from './upload-progress';
import DynamicInputOrSelect from './dynamic-input-select';
import TableCellAttachments from './table-cell-attachments';
import BizSelectOwner from './biz-select-owner';
import Despatch from './biz-despatch';
import BizVehicleItem from './biz-vehicle-item';
import TableTopSummary from './table-top-summary';
import BizPrintBarcode from './biz-print-barcode';
import ImageInfoViewer from './image-info-viewer';

const bundle = [
  BatchInput,
  Print,
  B2bProgress,
  b2bAddBox,
  b2bGenerateBox,
  b2bConfirm,
  B2bSwapDialog,
  BizMoveLocatipon,
  Tip,
  BizDialogParkConfig,
  CncEditableTableFormBtn,
  FormDownload,
  DownloadBtnPkmt,
  DownloadProgress,
  Export,
  ExportNew,
  FormItemWithIconClick,
  MultiItems,
  OwnerSelect,
  SorterRule,
  SorterRulePreview,
  TableCellColorfulText,
  TableCellImage,
  TableCellJsx,
  FormJsx,
  FormRelativeTime,
  Textarea,
  UploadBtnPkmt,
  UploadProgress,
  DynamicInputOrSelect,
  TableCellAttachments,
  BizSelectOwner,
  Despatch,
  BizVehicleItem,
  TableTopSummary,
  BizPrintBarcode,
  ImageInfoViewer,
];

export default bundle;
