import $i18n from '@alife/panda-i18n';
import React, { useCallback, useEffect, useState } from 'react';
import { CnAsyncSelect, CnButton, CnDialog, CnIcon, CnInput, CnMessage, Form, Field, } from '@alife/cn-ui';
import BindRvNoticeTable from './components/query';
import cnRequestWms from 'src/api';
import api from './api';
import Associated from './components/associated';
import { componentName } from './const';
import './index.scss';
const FormItem = Form.Item;
const BizVehicleItem = ({ record = {}, buttonConfig = {}, _context = {}, tableIds = [], }) => {
    const field = Field.useField({});
    const [tableId] = tableIds;
    const { id } = record;
    const { children } = buttonConfig;
    const [tableVisible, setTableVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [outboundBillRecords, setOutboundBillRecords] = useState();
    const [companyList, setCompanyList] = useState();
    const [associatedList, setAssociatedList] = useState();
    const [loading, setLoading] = useState(false);
    // 查询条件字段
    const getBindedRvNotice = useCallback(async () => {
        if (!id) {
            return;
        }
        try {
            setLoading(true);
            const { data: result } = (await cnRequestWms({
                url: api.unplanVehicleQueryBindedRvNotice,
                method: 'POST',
                data: {
                    id,
                },
            }));
            if (result) {
                setAssociatedList(result?.bindedOutOrderCodeList || []);
                setLoading(false);
            }
        }
        catch (e) {
            setLoading(false);
            CnMessage.error(e?.error?.message ||
                e?.error?.code ||
                $i18n.get({ id: 'NetworkAnomaly', dm: '网络异常' }));
        }
    }, [id]);
    // 删除关联
    const onCloseTag = useCallback((index) => {
        setOutboundBillRecords(outboundBillRecords?.filter((_unuse, i) => i !== index));
    }, [outboundBillRecords]);
    // 提交
    const handleSubmit = useCallback(async (values) => {
        try {
            const { vehicleNo, driver, driverContactNo, driverIdNo, companyCode } = values;
            const rvNoticeIdList = outboundBillRecords?.map((item) => {
                return item.id;
            });
            // 编辑情况
            if (id) {
                await cnRequestWms({
                    url: api.bindRvNotice,
                    method: 'POST',
                    data: {
                        id,
                        rvNoticeIdList,
                    },
                });
                CnMessage.success($i18n.get({ id: 'SavedSuccessfully', dm: '保存成功' }));
                tableId && _context.$(tableId)?.remoteInstance?.load?.();
            }
            else {
                const res = (await cnRequestWms({
                    url: api.createUnPlanVehicle,
                    method: 'POST',
                    data: {
                        rvNoticeIdList,
                        vehicleNo,
                        driver,
                        driverContactNo,
                        driverIdNo,
                        companyCode,
                    },
                }));
                const code = res?.data?.code || '';
                if (code) {
                    CnDialog.notice({
                        title: $i18n.get({ id: 'CreationSuccessful', dm: '创建成功' }),
                        content: $i18n.get({
                            id: 'DispatchNumberCode',
                            dm: '派车单号: {code}',
                        }, { code }),
                        onOk: () => {
                            tableId && _context.$(tableId)?.remoteInstance?.refresh?.();
                        },
                    });
                }
                else {
                    tableId && _context.$(tableId)?.remoteInstance?.load?.();
                }
                setVisible(false);
            }
            setOutboundBillRecords([]);
            getBindedRvNotice();
        }
        catch (e) {
            getBindedRvNotice();
            CnMessage.error(e?.error?.message ||
                e?.error?.code ||
                $i18n.get({ id: 'NetworkAnomaly', dm: '网络异常' }));
        }
    }, [getBindedRvNotice, id, outboundBillRecords, _context, tableId]);
    const getCompanyList = useCallback(async () => {
        const res = (await cnRequestWms({
            url: '/api/lp/vehicle/getScrapCompany',
            method: 'POST',
            data: {},
        }));
        setCompanyList(res?.data);
    }, []);
    useEffect(() => {
        getCompanyList();
    }, []);
    return (React.createElement(React.Fragment, null,
        id ? (React.createElement(CnButton, { type: 'primary', component: 'a', text: true, onClick: () => {
                setVisible(true);
            } }, children)) : (React.createElement(CnButton, { type: 'normal', onClick: () => {
                setVisible(true);
            } }, children)),
        visible ? (React.createElement(CnDialog, { title: id
                ? $i18n.get({ id: 'AssociatedOutcomeOrder', dm: '关联出库单' })
                : $i18n.get({
                    id: 'CreateAnUnplannedDispatchOrder',
                    dm: '创建无计划派车单',
                }), className: 'bind-rv-notice', visible: true, footer: false, onClose: () => {
                setAssociatedList([]);
                setCompanyList([]);
                setVisible(false);
                setOutboundBillRecords([]);
            } },
            React.createElement(Form, { field: field, useLabelForErrorMessage: true },
                !id ? (React.createElement(React.Fragment, null,
                    React.createElement(FormItem, { label: $i18n.get({
                            id: '31255238556127232.CNTM',
                            dm: '车牌号',
                        }), required: true, name: 'vehicleNo' },
                        React.createElement(CnInput, { placeholder: $i18n.get({
                                id: 'gxms-pc_Enter.CNTM',
                                dm: '请输入',
                            }), style: { width: '100%' } })),
                    React.createElement(FormItem, { label: $i18n.get({ id: 'ThePickup', dm: '取件人' }), required: true, name: 'driver' },
                        React.createElement(CnInput, { placeholder: $i18n.get({
                                id: 'gxms-pc_Enter.CNTM',
                                dm: '请输入',
                            }), style: { width: '100%' } })),
                    React.createElement(FormItem, { label: $i18n.get({
                            id: 'ContactInformation',
                            dm: '联系方式',
                        }), required: true, name: 'driverContactNo' },
                        React.createElement(CnInput, { placeholder: $i18n.get({
                                id: 'gxms-pc_Enter.CNTM',
                                dm: '请输入',
                            }), style: { width: '100%' } })),
                    React.createElement(FormItem, { label: $i18n.get({ id: 'IDNumber', dm: '证件号码' }), required: true, name: 'driverIdNo' },
                        React.createElement(CnInput, { placeholder: $i18n.get({
                                id: 'gxms-pc_Enter.CNTM',
                                dm: '请输入',
                            }), style: { width: '100%' } })),
                    companyList?.length ? (React.createElement(FormItem, { label: $i18n.get({
                            id: 'NameOfScrappingCompany',
                            dm: '报废公司名称',
                        }), required: true, name: 'companyCode' },
                        React.createElement(CnAsyncSelect, { showSearch: true, hasClear: true, dataSource: companyList, style: { width: '100%' } }))) : null)) : null,
                React.createElement(FormItem, { label: $i18n.get({
                        id: '31255241009795072.CNTM',
                        dm: '出库单号',
                    }), name: 'outboundBillRecords' },
                    React.createElement(CnButton, { type: 'normal', onClick: () => {
                            setTableVisible(true);
                        } }, $i18n.get({
                        id: 'AssociatedOutboundDocumentNumber',
                        dm: '关联出库单号',
                    })),
                    ' ',
                    React.createElement("div", { className: 'notice-list-content' }, outboundBillRecords?.map((item, index) => {
                        return (React.createElement("div", { className: 'level-normal' },
                            React.createElement("span", { className: 'content' }, item.noticeCode || ''),
                            React.createElement(CnIcon, { type: 'close', size: 'medium', onClick: () => {
                                    onCloseTag(index);
                                } })));
                    }))),
                React.createElement(Form.Item, null,
                    React.createElement(Form.Submit, { type: 'primary', validate: true, disabled: id ? !outboundBillRecords?.length : false, onClick: () => {
                            field.validate((error, v) => {
                                if (!error) {
                                    handleSubmit(v);
                                }
                            });
                        } }, $i18n.get({ id: '31255239613091840.CNTM', dm: '提交' })))),
            id ? (React.createElement(Associated, { id: id, getBindedRvNotice: getBindedRvNotice, associatedList: associatedList, loading: loading })) : null,
            tableVisible && (React.createElement(BindRvNoticeTable, { setTableVisible: setTableVisible, outboundBillRecords: outboundBillRecords, setOutboundBillRecords: setOutboundBillRecords })))) : null));
};
BizVehicleItem.displayName = componentName;
export { BizVehicleItem };
