// type CustomWindow = Window &
//   typeof globalThis & {
//     len: number | undefined;
//     radix: number | undefined;
//   };
// export function getUUID() {
//   const len =
//     typeof (window as CustomWindow).len === 'undefined'
//       ? 8
//       : (window as CustomWindow).len;
//   let radix =
//     typeof (window as CustomWindow).radix === 'undefined'
//       ? 16
//       : ((window as CustomWindow).radix as number);
//   const chars =
//     '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
//   const uuid = [];
//   let i;
//   radix = radix || chars.length;
//   if (len) {
//     // eslint-disable-next-line no-bitwise
//     for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
//   } else {
//     let r;
//     uuid[8] = '-';
//     uuid[13] = '-';
//     uuid[18] = '-';
//     uuid[23] = '-';
//     uuid[14] = '4';
//     for (i = 0; i < 36; i++) {
//       if (!uuid[i]) {
//         // eslint-disable-next-line no-bitwise
//         r = 0 | (Math.random() * 16);
//         // eslint-disable-next-line no-bitwise
//         uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
//       }
//     }
//   }
//   return uuid.join('');
// }
const domainMap = {
    data: {
        domestic: {
            daily: '//datahub.daily.taobao.net',
            pre: '//pre-data-wms.cainiao.com',
            pro: '//data-wms.cainiao.com',
        },
        sg: {
            pre: '//pre-wmsdatahub.sg.cainiao.com',
            pro: '//datahub.sg.cainiao.com',
        },
    },
};
export const domain = {
    domainMap,
    country: 'domestic',
    /**
     * 解析当前域名是什么环境
     * @param {*} domain
     */
    parseEnv(domainName) {
        // 环境, 默认为线上环境
        // HTTPS的线上环境也解析为HTTP环境
        let env = 'pro';
        // data-express 会命中 pre， 现调整为pre-
        if (domainName.indexOf('pre-') >= 0 ||
            domainName.indexOf('stg.') >= 0 ||
            domainName.indexOf('stg-') >= 0) {
            env = 'pre';
        }
        else if (domainName.indexOf('taobao.net') >= 0 ||
            domainName.indexOf('cainiao.test') >= 0) {
            // 解决某些日常的域名没有是taobao.net没有daily的问题 by xpp
            env = 'daily';
        }
        return env;
    },
    setCountry(country) {
        this.country = country;
    },
    /**
     * 设置domain
     * @param {*} moduleName 模块名称
     * @param {*} domainName
     */
    setDomain(moduleName, domainName) {
        this.domainMap[moduleName] = domainName;
    },
    getDomain(moduleName) {
        const env = this.parseEnv(window.location.host);
        return this.getHost(moduleName, env);
    },
    getHost(CODE, ENV) {
        const env = ENV || 'pro';
        if (this.domainMap[CODE]) {
            return this.domainMap[CODE][this.country][env] || '';
        }
        if (CODE === undefined) {
            return window.location.host;
        }
        return '';
    },
};
