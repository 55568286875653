import $i18n from '@alife/panda-i18n';
export const componentName = 'BizMoveLocation';
export const INVENTORY_TABLE_COL_LOCK = {
    availableInvQuantity: 'right',
    boxQuantity: 'right',
    planQuantity: 'right',
    toCabinetCode: 'right',
    boxRegulation: 'right',
};
export const initData = {
    ownerId: null,
    warehouseAreaId: null,
    createFormData: {
        type: 1,
    },
    infoData: {},
    filterData: {},
    dynamicTable: {
        paging: {
            currentPage: 1,
            pageSize: 10,
            totalItems: 0,
        },
    },
    detailTable: {
        paging: {
            currentPage: 1,
            pageSize: 10,
            totalItems: 0,
        },
    },
};
export const MOVE_LOC_TYPE_DATASOURCE = [
    {
        label: $i18n.get({ id: 'OneStepShift', dm: '一步移位' }),
        value: 1,
    },
    {
        label: $i18n.get({ id: 'TwostepShift', dm: '两步移位' }),
        value: 2,
    },
];
export const MOVE_EXT_TYPE = [
    {
        label: $i18n.get({ id: 'None', dm: '无' }),
        value: 0,
    },
    {
        label: $i18n.get({ id: 'DistributionSinking', dm: '分拨下沉' }),
        value: 2,
    },
    {
        label: $i18n.get({ id: 'MovingWarehouseAndShifting', dm: '搬仓移位' }),
        value: 3,
    },
];
