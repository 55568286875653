import $i18n from '@alife/panda-i18n';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Progress as CnProgress, CnMessage } from '@alife/cn-ui';
import { componentName } from './const';
import cnRequest from 'src/api';
import './index.scss';
/**
 * 定制业务组件，无复用能力
 * 供 b2b拣选单查询 使用
 */
const B2bProgress = ({ _getContext, onSuccess, }) => {
    const timerRef = useRef(0);
    const [progressNum, setProgressNum] = useState(0);
    const fetchProgress = useCallback(() => {
        const content = _getContext();
        const pickBillId = content?.state?.CnTable?.__tableCurrentRow__?.pickBillId;
        cnRequest({
            url: '/api/PC2BPickResource/pickProcess',
            method: 'post',
            data: {
                pickBillId,
                version: 1,
            },
        }).then((response) => {
            const percent = response?.data?.result?.percent || 0;
            setProgressNum(percent);
            if (percent >= 100) {
                CnMessage.success($i18n.get({ id: 'PickingConfirmedSuccess', dm: '拣选确认成功' }));
                content.$('node_oclbq7lycl2')?.close?.();
                content.$('node_ocl4dw4gow2')?.remoteInstance?.load?.();
                if (onSuccess) {
                    onSuccess();
                }
            }
            else {
                setTimeout(() => {
                    fetchProgress();
                }, 2000);
            }
        });
    }, [_getContext]);
    useEffect(() => {
        timerRef.current = progressNum;
    }, [progressNum]);
    useEffect(() => {
        fetchProgress();
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [fetchProgress]);
    return (React.createElement("div", { className: 'cn-sc-tip' },
        React.createElement(CnProgress, { percent: progressNum })));
};
B2bProgress.displayName = componentName;
export { B2bProgress };
