import $i18n from '@alife/panda-i18n';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import docCookie from 'cookie-curd';
// import { CnIcon } from '@alife/cn-ui';
import cnRequest from 'src/api';
import CncImportProgress from '@alife/cnc-upload-progress';
import './index.scss';

export default function UploadBtnPkmt(props) {
  const { tableRef = {} } = props;
  const [uploadHeader, setUploadHeader] = useState({});
  const onDemoClick = () => {
    const url =
      'https://pkmt.oss-cn-hangzhou.aliyuncs.com/template/materialInventoryImport.xls';
    const elink = document.createElement('a');
    elink.style.display = 'none';
    elink.target = '_blank';
    elink.href = url;
    elink.download = $i18n.get({ id: 'ExampleXlsx', dm: '示例.xlsx' });
    document.body.appendChild(elink);
    elink.click();
    document.body.removeChild(elink);
  };
  useEffect(() => {
    const { other } = window.currentMenu || {};
    if (other && other.wmsRequestMiddleware) {
      setUploadHeader({ 'x-check-csrf-token': 1 });

      let csrfId =
        docCookie.getItem('x-xsrf-token') || docCookie.getItem('bigful_usuc_t');

      // 临时hack http-only的csrf cookie前端拿不到的情况
      if (!csrfId) {
        try {
          cnRequest({
            url: '/api/csrf',
            method: 'get',
          }).then(
            (response) => {
              csrfId = response.data.token;
            },
            () => {},
          );
        } catch (e) {
          console.error('hack http-only csrf 失败');
        }
      }
      setUploadHeader({ ...uploadHeader, 'x-xsrf-token': csrfId });
    }
  }, []);
  const onClose = () => {
    tableRef.load();
  };
  return (
    <div className='uploadBtnPkmt'>
      <CncImportProgress
        title={$i18n.get({ id: 'Import', dm: '导入' })}
        uploadProps={{
          accept:
            '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.xls,.xlsx',
          headers: uploadHeader,
        }}
        onDemoClick={onDemoClick}
        demoText={$i18n.get({ id: 'ExampleXlsx', dm: '示例.xlsx' })}
        onClose={onClose}
        upload={async (info) => {
          try {
            const formData = new FormData();
            formData.append('file', info, info?.name || '');
            const _res = await cnRequest({
              url: '/api/pkmt/inventory/import',
              method: 'POST',
              data: formData,
              // responseType: 'blob',
            });
            // let result = await _res.data.text();
            // result = JSON.parse(result);
            // 全对
            if (_res.success) {
              return {
                type: 'successAll',
              };
            } else {
              return {
                type: 'failAll',
                errorMessage:
                  _res?.errorMsg ||
                  _res?.errorCode ||
                  _res?.error?.message ||
                  _res?.error?.code,
              };
            }
          } catch (e) {
            return {
              type: 'failAll',
              errorMessage:
                e?.errorMsg ||
                e?.errorCode ||
                e?.error?.message ||
                e?.error?.code,
            };
          }
        }}
      />
    </div>
  );
}

export { UploadBtnPkmt };

UploadBtnPkmt.displayName = 'UploadBtnPkmt';

UploadBtnPkmt.propTypes = {
  tableRef: PropTypes.object,
};

UploadBtnPkmt.defaultProps = {
  children: null,
};
