import $i18n from '@alife/panda-i18n';
import React, { useCallback } from 'react';
import { CnIcon, Input, Form, Select, Grid, Collapse } from '@alife/cn-ui';
import get from 'lodash/get';
import fp from 'lodash/fp';
import { AddressWrapper } from '../address-wrapper';
import { COMPONENT_TYPE } from '../../type';
// import { withI18n } from '@alife/panda-i18n';
// import locale from 'src/locale';
import './index.scss';
const { Row, Col } = Grid;
const Panel = Collapse.Panel;
export const DynamicInputs = (props) => {
    const { value = { ruleList: [] }, setValue = () => { }, priorityDatasource = [], matchDatasource = [], } = props;
    const { ruleList = [] } = value;
    const handleRemoveRuleByIndex = useCallback((index) => {
        setValue({
            ...value,
            ruleList: ruleList.filter((__unuse, idx) => index !== idx),
        });
    }, [value, setValue, ruleList]);
    const handleAddRule = useCallback(() => {
        setValue({
            ...value,
            ruleList: [
                ...ruleList,
                {
                    ruleItemList: [
                        { matchField: null, matchType: null, matchValue: null },
                    ],
                    priority: 2,
                },
            ],
        });
    }, [value, setValue, ruleList]);
    const handleRemoveRuleItemByIndex = useCallback((parentIndex, index) => {
        setValue({
            ...value,
            ruleList: ruleList.map((rule, idx) => {
                const { ruleItemList } = rule;
                if (parentIndex !== idx) {
                    return rule;
                }
                return {
                    ...rule,
                    ruleItemList: ruleItemList?.filter((__unuse, i) => index !== i),
                };
            }),
        });
    }, [value, setValue, ruleList]);
    const handleAddRuleItemCell = useCallback((parentIndex) => {
        setValue({
            ...value,
            ruleList: ruleList.map((item, idx) => {
                const { ruleItemList = [] } = item;
                if (parentIndex === idx) {
                    return {
                        ...item,
                        ruleItemList: [
                            ...ruleItemList,
                            { matchField: null, matchType: null, matchValue: null },
                        ],
                    };
                }
                return item;
            }),
        });
    }, [value, setValue, ruleList]);
    const renderRuleItemListCell = useCallback((ruleItemListCell, parentIndex, ruleItemListCellIndex) => {
        const matchField = get(value, `ruleList.${parentIndex}.ruleItemList.${ruleItemListCellIndex}.matchField`);
        const selectedMatchField = matchDatasource?.find((one) => one.value === matchField);
        const matchType = get(value, `ruleList.${parentIndex}.ruleItemList.${ruleItemListCellIndex}.matchType`);
        const matchValue = get(value, `ruleList.${parentIndex}.ruleItemList.${ruleItemListCellIndex}.matchValue`);
        const matchValueList = get(value, `ruleList.${parentIndex}.ruleItemList.${ruleItemListCellIndex}.matchValueList`, []);
        const type = selectedMatchField?.valueInput;
        const ruleItemList = get(value, `ruleList.${parentIndex}.ruleItemList`, []);
        const matchTypeOptions = selectedMatchField?.matchType || [];
        const matchValueOption = selectedMatchField?.valueOption || [];
        return (React.createElement(Row, null,
            React.createElement(Col, { span: 7 },
                React.createElement(Form.Item, { name: `tmp.ruleList.${parentIndex}.ruleItemList.${ruleItemListCellIndex}.matchField`, label: $i18n.get({ id: 'MatchField', dm: '匹配字段' }), required: true },
                    React.createElement(Select, { hasClear: true, dataSource: matchDatasource, value: matchField, onChange: (v) => {
                            const ruleItemListItem = get(value, [
                                'ruleList',
                                parentIndex,
                                'ruleItemList',
                                ruleItemListCellIndex,
                            ]);
                            const newValue = fp.set([
                                'ruleList',
                                parentIndex,
                                'ruleItemList',
                                ruleItemListCellIndex,
                            ], {
                                ...ruleItemListItem,
                                matchField: v,
                                matchType: null,
                                matchValue: null,
                                matchValueList: null,
                                matchValueDesc: null,
                            }, value);
                            setValue(newValue);
                        } }))),
            React.createElement(Col, { span: 7 },
                React.createElement(Form.Item, { label: $i18n.get({ id: 'MatchingMode', dm: '匹配方式' }), required: true, name: `tmp.ruleList.${parentIndex}.ruleItemList.${ruleItemListCellIndex}.matchType` },
                    React.createElement(Select, { hasClear: true, dataSource: matchTypeOptions, value: matchType, onChange: (v) => {
                            const ruleItemListItem = get(value, [
                                'ruleList',
                                parentIndex,
                                'ruleItemList',
                                ruleItemListCellIndex,
                            ]);
                            const newValue = fp.set([
                                'ruleList',
                                parentIndex,
                                'ruleItemList',
                                ruleItemListCellIndex,
                            ], {
                                ...ruleItemListItem,
                                matchType: v,
                                matchValue: null,
                            }, value);
                            setValue(newValue);
                        } }))),
            React.createElement(Col, { span: 7 },
                React.createElement(Form.Item, { label: $i18n.get({ id: 'MatchingValue', dm: '匹配值' }), name: `tmp.ruleList.${parentIndex}.ruleItemList.${ruleItemListCellIndex}.matchValue`, 
                    // validateState={'error'}
                    // help='匹配值 是必填字段'
                    required: true, 
                    // validatorTrigger={['onChange', 'onBlur']}
                    autoValidate: true },
                    type &&
                        [COMPONENT_TYPE.SELECT_MULTI, COMPONENT_TYPE.SELECT].includes(type) && (React.createElement(Select, { hasClear: true, dataSource: matchValueOption, disabled: typeof matchField === 'undefined' || matchField === '', showSearch: true, value: type === COMPONENT_TYPE.SELECT_MULTI
                            ? matchValueList
                            : matchValue, onChange: (v) => {
                            setValue(fp.set([
                                'ruleList',
                                parentIndex,
                                'ruleItemList',
                                ruleItemListCellIndex,
                                type === COMPONENT_TYPE.SELECT_MULTI
                                    ? 'matchValueList'
                                    : 'matchValue',
                            ], v, value));
                        }, mode: type === COMPONENT_TYPE.SELECT_MULTI
                            ? 'multiple'
                            : 'single', ...(type === COMPONENT_TYPE.SELECT_MULTI
                            ? {
                                maxTagCount: 1,
                            }
                            : null) })),
                    type === COMPONENT_TYPE.ADDRESS && (React.createElement(AddressWrapper, { value: matchValueList, fieldValue: value, parentIndex: parentIndex, ruleItemListCellIndex: ruleItemListCellIndex, setValue: setValue })),
                    ![
                        COMPONENT_TYPE.SELECT_MULTI,
                        COMPONENT_TYPE.SELECT,
                        COMPONENT_TYPE.ADDRESS,
                    ].includes(type) && (React.createElement(Input, { value: matchValue, onChange: (v) => {
                            setValue(fp.set(`ruleList.${parentIndex}.ruleItemList.${ruleItemListCellIndex}.matchValue`, v, value));
                        } })))),
            React.createElement(Col, { span: 3 }, (ruleItemList.length || 0) > 1 && (React.createElement(CnIcon, { type: 'minus-r', size: 'medium', style: { marginLeft: 8 }, onClick: () => {
                    handleRemoveRuleItemByIndex(parentIndex, ruleItemListCellIndex);
                } })))));
    }, [matchDatasource, handleRemoveRuleItemByIndex, value, setValue]);
    const renderRuleList = useCallback(() => {
        return ruleList.map((item, index) => {
            const { ruleItemList = [], priority } = item;
            return (React.createElement("div", { key: `parent_${index}`, style: { marginBottom: 16 } },
                React.createElement(Collapse, { className: 'collapse-rule', expandedKeys: ruleList?.map((__unuse, idx) => `${idx}`), defaultExpandedKeys: ruleList?.map((__unuse, idx) => `${idx}`) },
                    React.createElement(Panel, { title: React.createElement("div", { className: 'rule-item-title' },
                            React.createElement(Form.Item, null,
                                React.createElement("div", { className: 'rule-item-title-priority' },
                                    React.createElement("span", { className: 'rule-item-title-priority-label' }, $i18n.get({ id: 'Priority', dm: '优先级' })),
                                    React.createElement(Select, { dataSource: priorityDatasource, value: priority, onChange: (v) => {
                                            setValue(fp.set(`ruleList.${index}.priority`, v, value));
                                        } })),
                                ruleList.length > 1 && (React.createElement(CnIcon, { type: 'minus-r', size: 'medium', onClick: () => {
                                        handleRemoveRuleByIndex(index);
                                    } })))) },
                        ruleItemList?.map((ruleItemListItem, i) => renderRuleItemListCell(ruleItemListItem, index, i)),
                        React.createElement("span", { onClick: () => {
                                handleAddRuleItemCell(index);
                            } },
                            React.createElement(CnIcon, { type: 'add-r', size: 'medium' }),
                            $i18n.get({ id: 'AddField', dm: '添加字段' }))))));
        });
    }, [
        ruleList,
        handleRemoveRuleByIndex,
        renderRuleItemListCell,
        handleAddRuleItemCell,
        priorityDatasource,
        setValue,
        value,
    ]);
    return (React.createElement(React.Fragment, null,
        renderRuleList(),
        React.createElement("div", { className: 'add-line', onClick: () => {
                handleAddRule();
            } },
            React.createElement(CnIcon, { type: 'add-r', size: 'medium', style: { marginLeft: 8 } }),
            $i18n.get({ id: 'AddRule', dm: '添加规则' }))));
};
