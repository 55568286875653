import $i18n from '@alife/panda-i18n';
import React, { useCallback, useMemo } from 'react';
import { CnDialog, Field, Form, Select } from '@alife/cn-ui';
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
export default function SelectPrint(props) {
    const { visible, setVisible, printerList, 
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onOk = () => { }, } = props;
    const field = Field.useField();
    const handleOk = useCallback(() => {
        field.validate((errors, values) => {
            if (errors) {
                return;
            }
            const { printName } = values;
            const printer = printerList?.find((prt) => prt.label === printName);
            onOk(printer);
            setVisible(false);
        });
    }, [field, onOk, printerList, setVisible]);
    const formatedDataSource = useMemo(() => {
        return printerList?.map((item) => ({
            ...item,
            value: item.label,
        }));
    }, [printerList]);
    return (React.createElement(CnDialog, { title: $i18n.get({ id: 'SelectPrinter', dm: '选择打印机' }), visible: visible, style: { width: '400px' }, onOk: handleOk, onCancel: () => {
            setVisible && setVisible(false);
        }, onClose: () => {
            setVisible && setVisible(false);
        } },
        React.createElement(Form, { field: field },
            React.createElement(FormItem, { label: $i18n.get({ id: 'Printer', dm: '打印机' }), ...formItemLayout, required: true },
                React.createElement(Select, { ...field.init('printName'), style: { width: '100%' }, dataSource: formatedDataSource })))));
}
