import $i18n from '@alife/panda-i18n';
import React, { useState, useEffect, useCallback } from 'react';
import {
  CnInput,
  CnAsyncSelect,
  CnFilter,
  CnFilterItem,
  CnMessage,
  CnRangeDatePickerPro,
} from '@alife/cn-ui';
import { BatchInput } from 'src/components/batch-input';
import { OwnerSelect } from 'src/components/owner-select';
import { wmsCnRequestV3 as cnRequestWms } from '@alife/cn-biz-utils-sc';
import api from './api';

export default function Filter({ filterProps }) {
  const { field } = filterProps;
  const filterConfigs = {
    // 查询配置开关
    enableConfig: true,
    // 查询习惯开关
    enableSaveSelected: true,
    // 显示当前查询条件（tag），默认不显示
    showSelected: false,
    // 查询条件默认值
    initValues: {},
    // TODO：手动设置查询条件key值
    storageKey: '',
    // TODO：后端保存查询条件(如果不需要请删除onGetStorage和onSetStorage方法)
    showFolder: false,
  };
  const [dataSourceList, setDataSourceList] = useState({
    partnerList: [],
    customerList: [],
    orderTypeList: [],
  });
  const [statusList, setStatusList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);

  // 查询条件字段
  const getSelectData = useCallback(async () => {
    try {
      const { data: result = {} } = await cnRequestWms({
        url: api.getBindSelectList,
        method: 'POST',
        data: {},
      });

      if (result) {
        setDataSourceList(result);
      }
    } catch (e) {
      CnMessage.error(
        e?.message ||
          e?.code ||
          $i18n.get({ id: 'NetworkAnomaly', dm: '网络异常' }),
      );
    }
  }, []);

  const getStatusList = useCallback(async () => {
    try {
      const { data: result = {} } = await cnRequestWms({
        url: api.getStatusList,
        method: 'GET',
        data: {},
      });

      if (result) {
        setStatusList(result);
      }
    } catch (e) {
      CnMessage.error(
        e?.message ||
          e?.code ||
          $i18n.get({ id: 'NetworkAnomaly', dm: '网络异常' }),
      );
    }
  }, []);

  const getPartner = useCallback(async () => {
    try {
      const { data: result = {} } = await cnRequestWms({
        url: api.getPartner,
        method: 'GET',
        data: {},
      });

      if (result) {
        setPartnerList(result);
      }
    } catch (e) {
      CnMessage.error(
        e?.message ||
          e?.code ||
          $i18n.get({ id: 'NetworkAnomaly', dm: '网络异常' }),
      );
    }
  }, []);

  useEffect(() => {
    const { initValues } = filterConfigs;
    if (initValues) {
      field.setValues(initValues);
    }
    getSelectData();
    getStatusList();
    getPartner();
  }, []);

  return (
    <CnFilter {...filterConfigs} {...filterProps}>
      <CnFilterItem
        label={$i18n.get({ id: '31255238560321536.CNTM', dm: '创建时间' })}
        span={2}
      >
        <CnRangeDatePickerPro
          name={'createTime'}
          outputFormat='YYYY/MM/DD'
          hasClear
          defaultValue={[
            window.dayjs?.()?.add(-6, 'd')?.format('YYYY-MM-DD'),
            window?.dayjs?.()?.format('YYYY-MM-DD'),
          ]}
        />
      </CnFilterItem>
      <CnFilterItem
        required
        label={$i18n.get({ id: 'OutboundType', dm: '出库类型' })}
      >
        <CnAsyncSelect
          name={'orderType'}
          showSearch
          hasClear
          dataSource={dataSourceList?.orderTypeList || []}
          placeholder={$i18n.get({ id: 'PleaseSelect', dm: '请选择' })}
        />
      </CnFilterItem>
      <CnFilterItem
        label={$i18n.get({
          id: 'OutgoingNotificationNumber',
          dm: '出库通知单号',
        })}
      >
        <BatchInput
          name={'queryNoticeCode'}
          placeholder={$i18n.get({ id: 'gxms-pc_Enter.CNTM', dm: '请输入' })}
        />
      </CnFilterItem>
      <CnFilterItem
        label={$i18n.get({ id: '31255237851484160.CNTM', dm: '外部单号' })}
      >
        <BatchInput
          name={'queryOrdercode'}
          placeholder={$i18n.get({ id: 'gxms-pc_Enter.CNTM', dm: '请输入' })}
        />
      </CnFilterItem>
      <CnFilterItem label={$i18n.get({ id: 'FreightNumber', dm: '货运单号' })}>
        <CnInput
          name={'freightCode'}
          hasClear
          placeholder={$i18n.get({ id: 'gxms-pc_Enter.CNTM', dm: '请输入' })}
        />
      </CnFilterItem>
      <CnFilterItem label={$i18n.get({ id: 'Number', dm: '合单号' })}>
        <BatchInput
          name={'queryAssembleCodes'}
          placeholder={$i18n.get({ id: 'gxms-pc_Enter.CNTM', dm: '请输入' })}
        />
      </CnFilterItem>
      <CnFilterItem
        label={$i18n.get({ id: '31255241940930560.CNTM', dm: '货主' })}
      >
        <OwnerSelect
          name={'queryOwnerUserIds'}
          hasClear
          type='multi'
          placeholder={$i18n.get({ id: 'PleaseSelect', dm: '请选择' })}
        />
      </CnFilterItem>
      <CnFilterItem
        label={$i18n.get({ id: 'CustomerInformation', dm: '客户信息' })}
      >
        <CnAsyncSelect
          name={'queryCustomerIds'}
          showSearch
          hasClear
          dataSource={dataSourceList?.customerList || []}
          placeholder={$i18n.get({ id: 'PleaseSelect', dm: '请选择' })}
        />
      </CnFilterItem>
      <CnFilterItem label={$i18n.get({ id: 'OutboundMode', dm: '出库方式' })}>
        <CnAsyncSelect
          name={'deliveryType'}
          showSearch
          hasClear
          placeholder={$i18n.get({ id: 'PleaseSelect', dm: '请选择' })}
          remoteUrl='/api/lp/noticeList/deliveryType'
        />
      </CnFilterItem>
      <CnFilterItem label={$i18n.get({ id: 'ExpressCompany', dm: '快递公司' })}>
        <CnAsyncSelect
          name={'partnerIdList'}
          showSearch
          hasClear
          dataSource={partnerList || []}
          placeholder={$i18n.get({ id: 'PleaseSelect', dm: '请选择' })}
        />
      </CnFilterItem>
      <CnFilterItem
        label={$i18n.get({ id: '31255238514184192.CNTM', dm: '状态' })}
      >
        <CnAsyncSelect
          name={'status'}
          showSearch
          hasClear
          dataSource={statusList || []}
          placeholder={$i18n.get({ id: 'PleaseSelect', dm: '请选择' })}
        />
      </CnFilterItem>
      <CnFilterItem
        label={$i18n.get({
          id: 'WhetherTheCollectionIsCompleted',
          dm: '是否集货完成',
        })}
      >
        <CnAsyncSelect
          name={'finishedStaging'}
          showSearch
          hasClear
          dataSource={[
            { value: true, label: $i18n.get({ id: 'Yes', dm: '是' }) },
            {
              value: false,
              label: $i18n.get({ id: '31255237809541120.CNTM', dm: '否' }),
            },
          ]}
          placeholder={$i18n.get({ id: 'PleaseSelect', dm: '请选择' })}
        />
      </CnFilterItem>

      <CnFilterItem
        label={$i18n.get({ id: 'EstimatedOutboundTime', dm: '预计出库时间' })}
        span={2}
      >
        <CnRangeDatePickerPro
          name={'estimateOutTime'}
          outputFormat='YYYY/MM/DD'
          hasClear
        />
      </CnFilterItem>
    </CnFilter>
  );
}
