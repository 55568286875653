import $i18n from '@alife/panda-i18n';
import React, { useCallback, useEffect } from 'react';
import { CnDialog, CnResult, Box, Button, CnProgress } from '@alife/cn-ui';
import { getSomeStatusJobDesc, downloadFile } from './util';
import PropTypes from 'prop-types';
const cssPrefix = 'cn-ui-sync-dialog';

export default function LoopDialog(props) {
  const { onClose, onRender, visible, setVisible, data } = props;
  const {
    downloadUrl,
    errorDownloadUrl,
    status,
    reason,
    totalConut,
    processedCount,
  } = data;
  const close = useCallback(() => {
    setVisible(false);
    onClose();
  }, [onClose, setVisible]);

  useEffect(() => {
    if (!visible) return;
    onRender();
  }, [onRender, visible]);

  return (
    <CnDialog
      closeMode={status === 'EXECUTING' ? [] : ['close']}
      size='small'
      title={$i18n.get({ id: 'Import', dm: '导入' })}
      visible={visible}
      onCancel={close}
      onOk={close}
      okProps={{ visible: false }}
      cancelProps={{ visible: false }}
      onClose={close}
      cache={false}
    >
      {status === 'EXECUTING' ? (
        <Box
          className={`${cssPrefix}-job-process`}
          justify='center'
          direction='column'
          align='center'
          spacing={12}
        >
          {/* <Progress
            className={`${cssPrefix}-running`}
            progressive
            percent={progress}
            shape='circle'
          /> */}

          <div className={`${cssPrefix}-running-tip`}>
            <div>{'导入过程中，请勿关闭导入窗口'}</div>
            <div>
              {totalConut > 0 ? (
                <>
                  文件导入进度
                  <CnProgress
                    percent={(processedCount / totalConut) * 100}
                    textRender={() => `${processedCount}/${totalConut}`}
                  />
                </>
              ) : (
                '文件导入中……'
              )}
            </div>
          </div>
        </Box>
      ) : null}

      {status === 'FAILED' ? (
        <CnResult
          size='mini'
          type='error'
          title={$i18n.get({ id: 'DataImportFailed', dm: '数据导入失败' })}
          subTitle={reason}
          extra={
            errorDownloadUrl ? (
              <Button
                size='small'
                onClick={() => downloadFile(errorDownloadUrl)}
              >
                {$i18n.get({ id: 'ViewFailureRecords', dm: '查看失败记录' })}
              </Button>
            ) : null
          }
        />
      ) : null}

      {status === 'SOME' ? (
        <CnResult
          size='mini'
          type='warning'
          title={$i18n.get({
            id: 'SomeDataImportedSuccessfully',
            dm: '部分数据导入成功',
          })}
          subTitle={getSomeStatusJobDesc(data)}
          extra={
            <>
              {errorDownloadUrl ? (
                <Button
                  size='small'
                  onClick={() => downloadFile(errorDownloadUrl)}
                >
                  {$i18n.get({ id: 'ViewFailureRecords', dm: '查看失败记录' })}
                </Button>
              ) : null}

              {downloadUrl ? (
                <Button size='small' onClick={() => downloadFile(downloadUrl)}>
                  {$i18n.get({ id: 'ViewSuccessRecords', dm: '查看成功记录' })}
                </Button>
              ) : null}
            </>
          }
        />
      ) : null}

      {status === 'SUCCEEDED' ? (
        <CnResult
          size='mini'
          type='success'
          title={$i18n.get({
            id: 'CongratulationsDataImportSuccessful',
            dm: '恭喜您！数据导入成功',
          })}
          extra={
            downloadUrl ? (
              <Button size='small' onClick={() => downloadFile(downloadUrl)}>
                {$i18n.get({ id: 'Download', dm: '下载' })}
              </Button>
            ) : null
          }
        />
      ) : null}
    </CnDialog>
  );
}

LoopDialog.displayName = 'LoopDialog';

LoopDialog.propTypes = {
  onClose: PropTypes.func,
  onRender: PropTypes.func,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  data: PropTypes.objectOf({
    downloadUrl: PropTypes.string,
    errorDownloadUrl: PropTypes.string,
    status: PropTypes.string,
    reason: PropTypes.string,
    progress: PropTypes.number,
  }),
};
