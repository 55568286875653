import './action.scss';

import React, { forwardRef } from 'react';
import classNames from 'classnames';

export default forwardRef(
  ({ active, className, cursor, style, ...props }, ref) => {
    return (
      <button
        ref={ref}
        {...props}
        className={classNames('habbit-action', className)}
        tabIndex={0}
        style={{
          ...style,
          cursor,
          '--fill': active?.fill,
          '--background': active?.background,
        }}
      />
    );
  },
);
