import { wmsCnRequestV3 } from '@alife/cn-biz-utils-sc';
export function getItemCenterHost(location, mockUrl) {
    let { href: url } = location || {};
    const { hostname: host } = location || {};
    let oversea = '';
    if (host.indexOf('.sg') > -1) {
        oversea = '.sg';
    }
    else if (host.indexOf('.ru') > -1) {
        oversea = '.ru';
    }
    // mock
    if (mockUrl) {
        url = mockUrl;
    }
    // 日常
    else if (host.indexOf('.daily') > -1) {
        url = 'itemctr-wms.daily.taobao.net';
    }
    // 预发
    else if (host.indexOf('pre-') > -1 || host.indexOf('pre.') > -1) {
        url = `pre-itemctr.wms${oversea}.cainiao.com`;
    }
    // 预发新加坡
    else if (host.indexOf('-stg') > -1) {
        url = `itemctr-wms-stg${oversea}.cainiao.com`;
    }
    // 线上
    else {
        url = `itemctr-wms${oversea}.cainiao.com`;
    }
    return url;
}
export async function getOwners(hostName = window.location.hostname, value = '', params = '') {
    try {
        if (value) {
            if (value.includes('+')) {
                value = `${value}`.replace(/\+/g, encodeURIComponent('+'));
            }
            if (value.includes('&')) {
                value = `${value}`.replace(/&/g, encodeURIComponent('&'));
            }
        }
    }
    catch (e) {
        console.log(e, 'encodeURIComponent error');
    }
    return wmsCnRequestV3({
        url: `https://${hostName}/api/owner?keyword=${value}&pageSize=200${params}`,
        type: 'jsonp',
    });
}
