import './index.scss';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { CnNumberPicker, CnTimePickerPro } from '@alife/cn-ui';
function FormRelativeTime(prop) {
    const { value, onChange, isString = false } = prop || {};
    const onChangeRef = useRef(null);
    const [localValue, setLocalValue] = useState({});
    const [number = 0, time = ''] = useMemo(() => {
        let tmpNumber = 0;
        let tmpTime = '';
        if (isString) {
            if (typeof value === 'string') {
                const [n, t = ''] = value.split(' ');
                tmpNumber = parseInt(n ?? '0', 10);
                tmpTime = t;
            }
        }
        if (Array.isArray(value)) {
            return value;
        }
        return [tmpNumber, tmpTime];
    }, [isString, value]);
    useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);
    useEffect(() => {
        if (localValue && !Object.keys(localValue).length) {
            return;
        }
        if (isString) {
            onChangeRef.current?.([localValue.number || 0, localValue.time || ''].join(' '));
        }
        else {
            onChangeRef.current?.([localValue.number || 0, localValue.time || '']);
        }
    }, [localValue, isString]);
    return (React.createElement("div", { className: 'wms-biz-component-relative-time' },
        React.createElement(CnNumberPicker, { className: 'wms-biz-component-relative-time-number', size: 'small', value: number, innerBefore: (localValue?.number || 0) > 0 ? (React.createElement("span", { className: 'wms-biz-component-relative-time-number-before' }, "+")) : (''), onChange: (v) => setLocalValue({ time, number: v }) }),
        React.createElement(CnTimePickerPro, { className: 'wms-biz-component-relative-time-time', size: 'small', value: time, outputFormat: 'YYYY-MM-DD HH:mm:ss', onChange: (unuse, strVal) => setLocalValue({ number, time: strVal }) })));
}
FormRelativeTime.displayName = 'FormRelativeTime';
export { FormRelativeTime };
