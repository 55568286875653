import $i18n from '@alife/panda-i18n';
import Cookie from 'js-cookie';
import locale from '../locale';
$i18n.addLocale(locale);
const formatedLanguage = {
    zh: 'zh-CN',
    en: 'en-US',
    ja: 'ja-JP',
    ko: 'ko-KR',
    ru: 'ru-RU',
}[Cookie.get('i18n_lang') || Cookie.get('lang') || 'zh'];
$i18n.setCustomLang(formatedLanguage);
console.log('【业务组件包wms-biz-components】 $i18n', $i18n);
// console.log('【业务组件包wms-biz-components】 cookie lang', cookieLang);
console.log('【业务组件包wms-biz-components】 $i18n lang', $i18n.getLang());
