/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/**
 * 基础 request method, 基于 CNRequest 实现 (背后是 axios 实例).
 * 这里可以做一些业务的特殊定制, 比如说统一的埋点上报, 以及通用的网络错误处理.
 */
import cnRequest from 'cn-request';
// todo 统一 trace 接入方式
const trace = window.trace || {};
// 用于创建 axios 实例, 和 axios.create() 入参一致; 查看更多介绍 => https://axios-http.com/zh/docs/instance
const axiosConfig = {
    timeout: 5000,
};
// 用于创建 CNRequest; 查看更多介绍 => https://npm.alibaba-inc.com/package/@alife/cn-request
const cnOptions = {
    // arms 的 trace 实例; 查看更多介绍 => https://yuque.antfin-inc.com/ali_arms/lav0sg/lh4stz
    armsTrace: trace,
};
// request 是一个 axios 实例
const request = cnRequest(axiosConfig, cnOptions);
// 做一些基于业务场景的定制
request.interceptors.response.use((response) => {
    const { data } = response;
    const { success } = data;
    if (!success) {
        return Promise.reject(data);
    }
    return data;
}, (error) => {
    return Promise.reject(error);
});
export default request;
