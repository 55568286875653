import $i18n from '@alife/panda-i18n';
import React, { useCallback, useEffect, useState } from 'react';
import { CnMessage, Form, CnInput, Field, CnAsyncSelect } from '@alife/cn-ui';
import { componentName } from './const';
import cnRequest from 'src/api';
import './index.scss';
const FormItem = Form.Item;
/*
 * 定制业务组件，无复用能力
 * 供 b2b拣选单查询 使用
 */
const B2bGenerateBox = ({ _context = {}, _dialogRef = {}, }) => {
    const currentRow = _context?.state?.CnTable?.__tableCurrentRow__ || {};
    const field = Field.useField();
    const [materialList, setMaterialList] = useState([]);
    const calcNum = useCallback((v) => {
        const { ownerId, pickBillId } = currentRow;
        const materialId = v;
        cnRequest({
            url: '/api/PC2BPickResource/calculateBoxNum',
            method: 'POST',
            data: {
                ownerId,
                materialId,
                pickBillId,
            },
        }).then((response) => {
            const num = response?.data?.result;
            field.setValue('boxNum', num);
        });
    }, [currentRow]);
    const handleSubmit = useCallback((v) => {
        const { pickBillId } = currentRow;
        const { boxNum } = v;
        cnRequest({
            url: '/api/PC2BPickResource/generateVirtualContainers',
            method: 'POST',
            data: {
                pickBillId,
                num: boxNum,
                type: 10,
            },
        }).then((response) => {
            if (response.success) {
                CnMessage.success($i18n.get({ id: 'OperationSuccessful', dm: '操作成功' }));
                _context.$('node_ocl4dw4gow2')?.remoteInstance?.load?.();
                _dialogRef?.close?.();
            }
        });
    }, []);
    useEffect(() => {
        const { ownerId } = currentRow;
        cnRequest({
            url: '/api/PC2BPickResource/queryMaterialByOwner',
            method: 'POST',
            data: {
                ownerId,
            },
        }).then((response) => {
            const materials = response?.data?.result || [];
            setMaterialList(materials?.map(({ materialName, materialId, }) => ({
                label: materialName,
                value: materialId,
            })));
        });
    }, []);
    useEffect(() => {
        const { ownerName } = currentRow;
        field.setValues({ ownerName });
    }, []);
    return (React.createElement("div", { className: 'cn-sc-generate-box' },
        React.createElement(Form, { field: field },
            React.createElement(FormItem, { required: true, label: $i18n.get({ id: 'NameOfOwner', dm: '货主名称' }) },
                React.createElement(CnInput, { name: 'ownerName', disabled: true })),
            React.createElement(FormItem, { required: true, label: $i18n.get({ id: '31255240678445056.CNTM', dm: '包材类型' }) },
                React.createElement(CnAsyncSelect, { style: { width: '100%' }, name: 'materialId', dataSource: materialList, onChange: (v) => {
                        field.setValue('boxNum', null);
                        calcNum(v);
                    } })),
            React.createElement(FormItem, { required: true, label: $i18n.get({ id: 'CalculateTheNumberOfBoxes', dm: '计算箱数' }) },
                React.createElement(CnInput, { name: 'boxNum', disabled: true })),
            React.createElement(Form.Item, { label: '', className: 'b2b-generate-box' },
                React.createElement(Form.Submit, { type: 'primary', validate: true, onClick: () => {
                        field.validate((error, v) => {
                            if (!error) {
                                handleSubmit(v);
                            }
                        });
                    } }, $i18n.get({ id: 'Determine', dm: '确定' }))))));
};
B2bGenerateBox.displayName = componentName;
export { B2bGenerateBox };
